export const calculateGaragePrice = (
  dimensions,
  garageOptions,
  dimensionsData,
  isWallTypeChecked
) => {
  const kosztBlachyPir = dimensionsData.find(
    (d) => d["Rozmiar (szer. x dł.)"] === "Koszt blachy - pir"
  )["Cena Netto (zł) zaciagana do konfiguratora"];
  const kosztBlachyFala = dimensionsData.find(
    (d) => d["Rozmiar (szer. x dł.)"] === "Koszt blachy - fala"
  )["Cena Netto (zł) zaciagana do konfiguratora"];
  const kosztBramyPir = dimensionsData.find(
    (d) => d["Rozmiar (szer. x dł.)"] === "Koszt bramy - pir"
  )["Cena Netto (zł) zaciagana do konfiguratora"];

  // Choose the appropriate cost of blacha based on wall type
  const kosztBlachy = isWallTypeChecked ? kosztBlachyFala : kosztBlachyPir;

  let garagePrice = 0;
  const garageLength = dimensions.length / 100;
  const garageWidth = dimensions.width / 100;
  const garageHeight = dimensions.height / 100;

  if (garageOptions.includes("3.1.a") || garageOptions.includes("3.1.b")) {
    garagePrice =
      (garageLength * 2 + (garageWidth / 2) * 2) *
        (garageHeight + 0.8) *
        kosztBlachy
    if (garageOptions.includes("3.1.a") && garageOptions.includes("3.1.b")) {
      garagePrice *= 2; // Double the price if both options are selected
    }
  }

  return garagePrice;
};


export const calculateGatePrice = (
  dimensions,
  garageOptions,
  dimensionsData
) => {
  const halfWidth = dimensions.width / 2;
  const gateWidth = halfWidth - 20;
  const gateHeight = dimensions.height - 39;

  //console.log(`Calculated gate dimensions - Width: ${gateWidth}, Height: ${gateHeight}`);

  const availableGates = dimensionsData
    .filter((d) => d["Rozmiar (szer. x dł.)"].startsWith("Brama"))
    .map((d) => {
      const [widthPart, heightPart] = d["Rozmiar (szer. x dł.)"]
        .replace("Brama -", "")
        .replace(/\s+/g, '')
        .trim()
        .split("x");

      return {
        width: parseInt(widthPart.trim(), 10),
        height: parseInt(heightPart.trim(), 10),
        price: d["Cena Netto (zł) zaciagana do konfiguratora"],
      };
    });

  //console.log(`Available gates: `, availableGates);

  const matchedGate = availableGates.find(
    (gate) => gate.width >= gateWidth && gate.height >= gateHeight
  );

  if (!matchedGate) {
    //console.log("No matched gate found for the given dimensions.");
    return 0; 
  }

  //console.log(`Matched gate: `, matchedGate);

  let gatePrice = matchedGate.price;

  if (garageOptions.includes("3.1.a") && garageOptions.includes("3.1.b")) {
    gatePrice *= 2;
  }

  gatePrice *= 1.2 * 1.23; 
  //console.log(`Final gate price: ${gatePrice}`);

  return gatePrice;
};
