import React from 'react';
import './EmailSendingIndicatorComponent.css'; 

const EmailSendingIndicatorComponent = ({ message, type }) => {
  return (
    <div className={`alert-component ${type}`}>
      {message}
    </div>
  );
};

export default EmailSendingIndicatorComponent;
