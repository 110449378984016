export const calculateMaskownicaPremiumPrice = (dimensionsData, dimensions, shedOptions) => {
    if (!shedOptions.isGrilleTypeChecked) return 0;
  
    const maskownicaPremium = dimensionsData.find(
      (d) => d["Rozmiar (szer. x dł.)"] === "Maskownica Premium"
    );
    const maskownicaPremiumPricePerMeter = maskownicaPremium
      ? maskownicaPremium["Cena Netto (zł) zaciagana do konfiguratora"]
      : 0;
  
    const perimeter = 2 * (dimensions.width / 100 + dimensions.length / 100);
    const maskownicaPremiumPrice = maskownicaPremiumPricePerMeter * perimeter;
    
    return maskownicaPremiumPrice;
  };
  