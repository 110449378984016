import { useSelector } from 'react-redux';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import getModelTypeText from './choiceTranslationUtils/modelTexts';
import { selectSelectedId as selectSelectedModelId } from '../../../store/reducers/modelChoiceSlice';
import { selectDimensionsBySelectedId } from '../../../store/reducers/dimensionsSlice';
import { selectShedOptionsState } from '../../../store/reducers/shedOptionsSlice';
import { selectColorChoices } from '../../../store/reducers/colorChoiceSlice';
import { selectSelectedId as selectSelectedPanelId } from '../../../store/reducers/panelChoiceSlice';
import { selectSelectedSquares } from '../../../store/reducers/panelPlacementSlice';
import { selectSelectedId as isSelectedPanelColorId } from '../../../store/reducers/panelColorChoiceSlice';
import {
  translateIsGarageChecked,
  translateIsRoomChecked,
  translateSelectedGarageOption,
  translateSelectedRoomOption,
  translateIsShedPositionChecked,
  translateShedPosition,
  translateIsSnowZoneChecked,
  translateisPhotovoltaicsReinforcementChecked
} from './choiceTranslationUtils/translateShedOptions';
import { translateColorCode } from './choiceTranslationUtils/translateColorCode';
import { translatePanelId } from './choiceTranslationUtils/panelIdTranslations';
import { countTrueValues } from './choiceTranslationUtils/countTrueValues';
import { translatePanelColorId } from './choiceTranslationUtils/panelColorTranslations';
import customFontBase64 from './choiceTranslationUtils/fonts/customFontBase64';
import abhayaBoldBase64 from './choiceTranslationUtils/fonts/abhayaBoldBase64';
import { selectEmail, selectFullName, selectPhone, selectPostalCode, selectFeedbackMessage } from '../../../store/reducers/formInfoSlice';
import { countSelectedSquaresByModel, modelToPanelPrefix } from './choiceTranslationUtils/countingUtils';
import useGenerateOrderNumber from './choiceTranslationUtils/generateOrderNumber';

export const useSummaryDataAndGeneratePDF = () => {
  const selectedModelId = useSelector(selectSelectedModelId);
  const dimensions = useSelector((state) => selectDimensionsBySelectedId(state, selectedModelId));
  const shedOptions = useSelector(selectShedOptionsState);
  const colorChoices = useSelector(selectColorChoices);
  const selectedPanelId = useSelector(selectSelectedPanelId);
  const selectedSquares = useSelector(selectSelectedSquares);
  const selectedPanelColorId = useSelector(isSelectedPanelColorId);

  const email = useSelector(selectEmail);
  const fullName = useSelector(selectFullName);
  const phone = useSelector(selectPhone);
  const postalCode = useSelector(selectPostalCode);
  const feedbackMessage= useSelector(selectFeedbackMessage);
  const generateOrderNumber = useGenerateOrderNumber();
  const orderNumber = generateOrderNumber();
  //console.log(orderNumber)
  const formattedWidth = parseInt(dimensions.width);
  const formattedLength = parseInt(dimensions.length);
  const formattedHeight = parseInt(dimensions.height);
  const formattedRoofDimension = parseInt(dimensions.roofDimension);
  const modelPrefix = modelToPanelPrefix[selectedModelId];
  let countOfSelectedSquares = countSelectedSquaresByModel(selectedSquares, modelPrefix);

  const createPDFDocument = async () => {
    return new Promise(async (resolve, reject) => {
      const v3dAppElement = document.querySelector('.v3d-container');
      if (!v3dAppElement) {
        reject('v3dAppElement not found');
        return;
      }
      const pixelRatio = window.devicePixelRatio || 1; 
      const scale = pixelRatio;  
      const canvasWidth = v3dAppElement.offsetWidth * scale;
      const canvasHeight = v3dAppElement.offsetHeight * scale;

      try {
        const canvas = await html2canvas(v3dAppElement, {
          width: v3dAppElement.offsetWidth,
          height: v3dAppElement.offsetHeight,
          scale: scale,  // Set the scale based on device pixel ratio
          logging: true, // Enable logging for debugging
          useCORS: true  // Use CORS to handle external resources
        });
        const imgData = canvas.toDataURL('image/jpeg', 0.4);
        const doc = new jsPDF();
        doc.addFileToVFS("CustomFutura.ttf", customFontBase64);
        doc.addFont("CustomFutura.ttf", "Futura", "bold", 'Unicode');
        doc.addFileToVFS("BoldAbhaya.ttf", abhayaBoldBase64);
        doc.addFont("BoldAbhaya.ttf", "BoldAbhaya", "bold", "Unicode");
        doc.setFillColor(229, 231, 227);
        doc.rect(0, 0, 210, 297, 'F');
        doc.setFillColor(144, 195, 56);
        const bottomRectYPosition = 297 - 9;
        doc.rect(0, bottomRectYPosition, 210, 9, 'F');
        const fontList = doc.getFontList();
        //console.log("Available fonts:", fontList);
        Object.keys(fontList).forEach(function (fontName) {
          //console.log(fontName + ': ' + fontList[fontName].join(', '));
        });
        const imgWidth = 210;
        const imgHeight = 100.95;
        doc.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
        doc.setTextColor(0, 0, 0);
        doc.setLanguage("pl");
        doc.setFont('BoldAbhaya', 'Unicodebold');
        doc.setFontSize(10);
        let yOffset = 84;
        doc.text("W Twoim wyborze kryje się nasza pasja!", 19.5, yOffset);
        doc.setFontSize(9);
        doc.setFont('Futura', 'Unicodebold');
        yOffset += 8.95;
        doc.text("Dziękujemy Ci za zaufanie i wybór naszej firmy. Poniżej znajdziesz szczegółowy opis Twojej spersonalizowanej wiaty ", 19.5, yOffset);
        yOffset += 4.5;
        doc.text("samochodowej. Spodziewaj się wkrótce naszego kontaktu lub skontaktuj się bezpośrednio z nami. Abyśmy mogli szybko", 19.5, yOffset);
        yOffset += 4.5;
        doc.text("Ci pomóc, prosimy podać numer oferty.", 19.5, yOffset);
        yOffset += 9;
        doc.text(`Oferta nr ${orderNumber} dla`, 19.5, yOffset);
        doc.setFont('BoldAbhaya', 'Unicodebold');
        doc.text(":", 59, yOffset);
        doc.setFont('Futura', 'Unicodebold');
        yOffset += 4;
        doc.text(fullName, 19.5, yOffset);
        yOffset += 4.5;
        doc.text(phone, 19.5, yOffset);
        yOffset += 4.5;
        doc.text(email, 19.5, yOffset);
        yOffset += 8
        doc.setFont('BoldAbhaya', 'Unicodebold');
        doc.setFontSize(10);
        doc.text("Nazwa Produktu/Typ:", 19.5, yOffset);
        yOffset += 5
        doc.setFont('Futura', 'Unicodebold');
        doc.setFontSize(10);
        doc.text("Wiata Samochodowa /", 19.5, yOffset);
        doc.setFontSize(10);
        let modelTypeText = getModelTypeText(selectedModelId);
        if (modelTypeText) {
          doc.text(modelTypeText, 52.5, yOffset);
          yOffset += 8.5;
        }
        doc.setFontSize(10);
        doc.text("Szczegóły Konfiguracji", 19.5, yOffset);
        doc.setFont('BoldAbhaya', 'Unicodebold');
        doc.text(":", 52.8, yOffset);
        doc.setFont('Futura', 'Unicodebold');
        yOffset += 8.5;
        doc.setFont('BoldAbhaya', 'Unicodebold');
        doc.setFontSize(10);
        doc.text("Wymiary:", 19.5, yOffset);
        doc.setFont('Futura', 'Unicodebold');
        doc.setFontSize(10);
        yOffset += 4.5;
        doc.setFontSize(10);
        doc.text("Szerokość", 33, yOffset);
        doc.setFont('BoldAbhaya', 'Unicodebold');
        doc.text(":", 47.8, yOffset);
        doc.setFont('Futura', 'Unicodebold');
        doc.setFontSize(10);
        doc.text(`${formattedWidth} cm`, 80, yOffset);
        yOffset += 4.5;
        doc.setFontSize(10);
        doc.text("Długość", 33, yOffset);
        doc.setFont('BoldAbhaya', 'Unicodebold');
        doc.text(":", 44.8, yOffset);
        doc.setFont('Futura', 'Unicodebold');
        doc.setFontSize(10);
        doc.text(`${formattedLength} cm`, 80, yOffset);
        yOffset += 4.5;
        doc.setFontSize(10);
        doc.text("Wysokość", 33, yOffset);
        doc.setFont('BoldAbhaya', 'Unicodebold');
        doc.text(":", 47.4, yOffset);
        doc.setFont('Futura', 'Unicodebold');
        doc.setFontSize(10);
        doc.text(`${formattedHeight} cm`, 80, yOffset);
        yOffset += 4;
        doc.setFontSize(10);
        doc.text("Wysunięcie dachu", 33, yOffset);
        doc.setFont('BoldAbhaya', 'Unicodebold');
        doc.text(":", 59.4, yOffset);
        doc.setFont('Futura', 'Unicodebold');
        doc.setFontSize(10);
        doc.text(`${formattedRoofDimension} cm`, 80, yOffset);
        yOffset += 4.5;
        doc.setFont('BoldAbhaya', 'Unicodebold');
        doc.setFontSize(10);
        doc.text("Opcje wiaty:", 19.5, yOffset);
        doc.setFont('Futura', 'Unicodebold');
        yOffset += 4.5;
        doc.setFontSize(10);
        doc.text("Garaż", 33, yOffset);
        doc.setFont('BoldAbhaya', 'Unicodebold');
        doc.text(":", 41.7, yOffset);
        doc.setFont('Futura', 'Unicodebold');
        doc.setFontSize(10);
        doc.text(`${translateIsGarageChecked(shedOptions.isGarageChecked)},`, 80, yOffset);
        doc.text(`${translateSelectedGarageOption(shedOptions.selectedGarageOption)}`, 87, yOffset);
        yOffset += 4.1;
        doc.setFontSize(10);
        doc.text("Pomieszczenie gospodarcze", 33, yOffset);
        doc.setFont('BoldAbhaya', 'Unicodebold');
        doc.text(":", 73.2, yOffset);
        doc.setFont('Futura', 'Unicodebold');
        doc.setFontSize(10);
        doc.text(`${translateIsRoomChecked(shedOptions.isRoomChecked)}, `, 80, yOffset);
        doc.text(`${translateSelectedRoomOption(shedOptions.selectedRoomOption)}`, 87, yOffset);
        yOffset += 4.5;
        doc.setFontSize(10);
        doc.text("Położenie wiaty", 33, yOffset);
        doc.setFont('BoldAbhaya', 'Unicodebold');
        doc.text(":", 55.9, yOffset);
        doc.setFont('Futura', 'Unicodebold');
        doc.setFontSize(10);
        doc.text(`${translateIsShedPositionChecked(shedOptions.isShedPositionChecked)}`, 80, yOffset);
        doc.text(`${translateShedPosition(shedOptions.shedPosition)}`, 97, yOffset);
        yOffset += 4.5;
        doc.setFontSize(10);
        doc.text("Strefa śniegowa", 33, yOffset);
        doc.setFont('BoldAbhaya', 'Unicodebold');
        doc.text(":", 56.3, yOffset);
        doc.setFont('Futura', 'Unicodebold');
        doc.setFontSize(10);
        doc.text(`${translateIsSnowZoneChecked(shedOptions.isSnowZoneChecked)}`, 80, yOffset);
        yOffset += 4.5;
        doc.setFontSize(10);
        doc.text("Wzmocnienie pod fotowoltaikę", 33, yOffset);
        doc.setFont('BoldAbhaya', 'Unicodebold');
        doc.text(":", 78.3, yOffset);
        doc.setFont('Futura', 'Unicodebold');
        doc.setFontSize(10);
        doc.text(`${translateisPhotovoltaicsReinforcementChecked(shedOptions.isPhotovoltaicsReinforcementChecked)}`, 80, yOffset);
        yOffset += 4.5;
        doc.setFont('BoldAbhaya', 'Unicodebold');
        doc.setFontSize(10);
        doc.text("Kolor:", 19.5, yOffset);
        doc.setFont('Futura', 'Unicodebold');
        yOffset += 4.5;
        doc.setFontSize(10);
        doc.text("Słupy", 33, yOffset);
        doc.setFont('BoldAbhaya', 'Unicodebold');
        doc.text(":", 41.5, yOffset);
        doc.setFont('Futura', 'Unicodebold');
        doc.setFontSize(10);
        const poleColorName = translateColorCode(colorChoices.poleColor);
        doc.text(`${poleColorName}`, 80, yOffset);
        yOffset += 4.3;
        doc.setFontSize(10);
        doc.text("Dach", 33, yOffset);
        doc.setFont('BoldAbhaya', 'Unicodebold');
        doc.text(":", 40.5, yOffset);
        doc.setFont('Futura', 'Unicodebold');
        doc.setFontSize(10);
        const roofColorName = translateColorCode(colorChoices.roofColor);
        doc.text(`${roofColorName}`, 80, yOffset);
        yOffset += 4.3;
        doc.setFontSize(10);
        doc.text("Maskownica", 33, yOffset);
        doc.setFont('BoldAbhaya', 'Unicodebold');
        doc.text(":", 51.2, yOffset);
        doc.setFont('Futura', 'Unicodebold');
        doc.setFontSize(10);
        const facadeColorName = translateColorCode(colorChoices.facadeColor);
        doc.text(`${facadeColorName}`, 80, yOffset);
        yOffset += 4.3;
        doc.setFontSize(10);
        doc.text("Panel boczny", 33, yOffset);
        doc.setFont('BoldAbhaya', 'Unicodebold');
        doc.text(":", 52.2, yOffset);
        doc.setFont('Futura', 'Unicodebold');
        doc.setFontSize(10);
        const panelColorName = translatePanelColorId(selectedPanelColorId);
        yOffset += -0.3;
        doc.text(panelColorName, 80, yOffset);
        yOffset += 4.3;
        doc.setFont('BoldAbhaya', 'Unicodebold');
        doc.setFontSize(10);
        doc.text("Panel boczny:", 19.5, yOffset);
        doc.setFont('Futura', 'Unicodebold');
        yOffset += 4.7;
        doc.setFontSize(10);
        doc.text("Model", 33, yOffset);
        doc.setFont('BoldAbhaya', 'Unicodebold');
        doc.text(":", 42.2, yOffset);
        doc.setFont('Futura', 'Unicodebold');
        doc.setFontSize(10);
        const translatedPanelId = translatePanelId(selectedPanelId);
        doc.text(translatedPanelId, 80, yOffset);
        yOffset += 4.4;
        doc.setFontSize(10);
        doc.text("Ilość", 33, yOffset);
        doc.setFont('BoldAbhaya', 'Unicodebold');
        doc.text(":", 40.2, yOffset);
        doc.setFont('Futura', 'Unicodebold');
        doc.setFontSize(10);
        if (selectedModelId === '1.a' && selectedSquares['6.1.d']) {
          countOfSelectedSquares += 1; 
        }
        if (selectedModelId === '1.a') {
          countOfSelectedSquares = Math.max(0, countOfSelectedSquares - 1);
        }
 
        doc.text(countOfSelectedSquares + ' ', 80, yOffset);
        yOffset += 9;
        doc.setFontSize(10);
        doc.text("Uwagi klienta", 20, yOffset);
        doc.setFont('BoldAbhaya', 'Unicodebold');
        doc.text(" ", 46, yOffset);
        doc.setFont('Futura', 'Unicodebold');
        doc.text(" ", 47, yOffset);
        yOffset += 4;
        doc.setFont('BoldAbhaya', 'Unicodebold');
        doc.text(feedbackMessage, 20, yOffset);
        doc.setFont('Futura', 'Unicodebold');
        yOffset += 4;
   
        doc.text(" ", 31, yOffset);
        yOffset += 9;
        doc.text("Dziękujemy za zaufanie i wybór naszej firmy. W razie jakichkolwiek pytań lub wąpliwości, prosimy o kontakt. Jesteśmy", 20, yOffset);
        yOffset += 4.5;
        doc.text("do Twojej dyspozycji.", 20, yOffset);
        yOffset += 4.5;
        doc.text("z poważaniem,", 20, yOffset);
        const logo = 'logomarc.png';
        doc.addImage(logo, 'PNG', 19.5, 271, 40, 12);
        yOffset += 25.5;
        doc.text("kontakt@marciniakogrodzenia.pl", 3.5, yOffset);
        doc.text("+48 732 081 990", 177, yOffset);
        resolve(doc);
      } catch (error) {
        reject(error);
      }
    });
  };
  const downloadPDF = async () => {
    const doc = await createPDFDocument();
    doc.save(`Konfiguracja Wiaty nr ${orderNumber}.pdf`);
  };

  const getPdfBase64 = async () => {
    const doc = await createPDFDocument();
    const base64String = doc.output('datauristring').split(';base64,')[1];
    return base64String;
  };

  return { downloadPDF, getPdfBase64 };
  ;
}