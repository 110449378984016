export const getAntykondensatPrice = (dimensionsData) => {
  const antykondensat = dimensionsData.find(
    (d) => d["Rozmiar (szer. x dł.)"] === "Antykondensat"
  );
  return antykondensat
    ? antykondensat["Cena Netto (zł) zaciagana do konfiguratora"]
    : 0;
};

export const calculateAntykondensatCost = (dimensions, antykondensatPrice) => {
  return ((dimensions.width * dimensions.length) / 10000) * antykondensatPrice;
};
