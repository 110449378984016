import React, { useEffect, useState } from 'react';
import "./InfoIcon.css";

const InfoIcon = () => {
    const [size, setSize] = useState({ width: '200px', height: '200px' });

    useEffect(() => {
        const timer = setTimeout(() => {
            setSize({ width: '100px', height: '100px' });
        }, 6000);
        return () => clearTimeout(timer);
    }, []); 

    const handleMouseEnter = () => {
        setSize({ width: '300px', height: '300px' });
    };

    const handleMouseLeave = () => {
        setTimeout(() => {
            setSize({ width: '100px', height: '100px' });
        }, 1000);
    };

    return (
        <div className="info-class">
            <img
                src="icons2.png"
                alt="Icon"
                style={{ 
                    maxWidth: size.width, 
                    maxHeight: size.height,
                    transition: 'max-width 0.2s ease-in-out, max-height 0.2s ease-in-out' 
                }}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            />
        </div>
    );
};

export default InfoIcon;
