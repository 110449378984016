import { configureStore } from "@reduxjs/toolkit";
import modelChoiceReducer from "./reducers/modelChoiceSlice";
import colorChoiceReducer from "./reducers/colorChoiceSlice";
import shedOptionsReducer from "./reducers/shedOptionsSlice";
import panelChoiceReducer from "./reducers/panelChoiceSlice";
import panelColorChoiceReducer from "./reducers/panelColorChoiceSlice";
import panelPlacementReducer from "./reducers/panelPlacementSlice";
import dimensionsReducer from './reducers/dimensionsSlice';
import formInfoReducer from './reducers/formInfoSlice';
import finalPriceReducer from './reducers/finalPriceSlice'; // Import the new slice

export const store = configureStore({
  reducer: {
    modelChoice: modelChoiceReducer,
    colorChoice: colorChoiceReducer,
    shedOptions: shedOptionsReducer,
    panelChoice: panelChoiceReducer,
    panelColorChoice: panelColorChoiceReducer,
    panelPlacement: panelPlacementReducer,
    dimensions: dimensionsReducer,
    formInfo: formInfoReducer,
    finalPrice: finalPriceReducer, // Add the new slice to the store
  },
});
