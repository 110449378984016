const panelIdToName = {
  '5.a': 'Wellblech',
  '5.b': 'Horizontal',
  '5.c': 'Modern+',
  '5.d': 'Jalousie',
};

export const translatePanelId = (panelId) => {
  return panelIdToName[panelId] || panelId;
};
