import React from "react";
import "./InfoWindow.css";

const InfoWindow = ({ id, image, text, title, onClose }) => (
  <div className="info-window backdrop-blur-md bg-black bg-opacity-50">
    <div
      className="info-close flex justify-center items-center text-white"
      onClick={onClose}
    >
      X
    </div>
    <img className="info-image" src={image} alt="Info" />
    {title && <p className="info-title">{title}</p>}
    <p className="info-text">{text}</p>
    
  </div>
);
export default InfoWindow;
