import html2canvas from 'html2canvas';

const captureV3dContainerScreenshot = async () => {
  const v3dAppElement = document.querySelector('.v3d-container');
  if (!v3dAppElement) {
    throw new Error('v3dAppElement not found');
  }

  const pixelRatio = window.devicePixelRatio || 1;
  const scale = pixelRatio;
  const canvasWidth = v3dAppElement.offsetWidth * scale;
  const canvasHeight = v3dAppElement.offsetHeight * scale;

  try {
    const canvas = await html2canvas(v3dAppElement, {
      width: v3dAppElement.offsetWidth,
      height: v3dAppElement.offsetHeight,
      scale: scale,  // Set the scale based on device pixel ratio
      logging: true, // Enable logging for debugging
      useCORS: true  // Use CORS to handle external resources
    });
    return canvas.toDataURL('image/jpeg', 1);  // Returns image data URL as JPEG with quality 0.4
  } catch (error) {
    console.error('Error capturing screenshot:', error);
    throw error;
  }
};

export default captureV3dContainerScreenshot;
