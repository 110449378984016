// loadingUI.js

import { showLoadingTextLandscape } from "./loadingTextLandscape";
import { showLoadingTextPortrait } from "./loadingTextPortrait";
let isAppLoaded = false; // Add this flag to track the app load status

export function showLoadingText() {
  if (isAppLoaded) return; // If the app is already loaded, don't show the loading text again

  const isPortrait = window.matchMedia("(orientation: portrait)").matches;
  if (isPortrait) {
    showLoadingTextPortrait();
  } else {
    showLoadingTextLandscape();
  }

  window.addEventListener("resize", () => {
    if (isAppLoaded) return; // If the app is already loaded, don't recreate the loading text

    const loadingOverlay = document.getElementById("loadingOverlay");
    if (loadingOverlay) {
      loadingOverlay.remove();
    }
    showLoadingText();
  });
}

/**
 * Update the width of the loading bar based on the percentage loaded.
 * @param {number} percentage - The percentage of loading completed.
 */
export function updateLoadingBar(percentage) {
  const loadingBar = document.getElementById("loadingBar");
  if (loadingBar) {
    loadingBar.style.width = `${percentage}%`;
  }
}


export function hideLoadingText() {
  const loadingOverlay = document.getElementById("loadingOverlay");
  if (loadingOverlay) {
    loadingOverlay.remove();
  }
  isAppLoaded = true; // Mark the app as loaded once the loading screen is hidden
}
