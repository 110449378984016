import React from 'react';
import './SummaryInput.css';

const SummaryInput = ({ type = 'text', placeholder, value, onChange, isRequired = false, isValid = true, icon }) => {
  const className = `aesthetic-input ${!isValid && isRequired ? 'invalid' : ''}`;

  return (
    <div className="summary-input-container">
      {icon && <img src={icon} alt="" className="input-icon" />}
      <input
        className={className}
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        aria-required={isRequired}
        aria-invalid={!isValid && isRequired}
      />
    </div>
  );
};

export default SummaryInput;
