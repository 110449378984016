import React, { useEffect, useRef } from "react";
import "./PanelChoiceRhombus.css";
import { isIOS } from "../../utils/isIOS";

const PanelChoiceRhombus = ({ id, image, selected, label, toggleInfoWindow }) => {
  const infoIconRef = useRef(null);

  useEffect(() => {
    const handleTouchStart = (e) => {
      e.stopPropagation();
      toggleInfoWindow(id);
    };

    const handleClick = (e) => {
      e.stopPropagation();
      toggleInfoWindow(id);
    };

    const infoIconElement = infoIconRef.current;

    if (infoIconElement) {
      if (isIOS()) {
        infoIconElement.addEventListener("touchstart", handleTouchStart);
      } else {
        infoIconElement.addEventListener("click", handleClick);
      }
    }

    // Cleanup event listeners on component unmount
    return () => {
      if (infoIconElement) {
        if (isIOS()) {
          infoIconElement.removeEventListener("touchstart", handleTouchStart);
        } else {
          infoIconElement.removeEventListener("click", handleClick);
        }
      }
    };
  }, [id, toggleInfoWindow]);

  return (
    <div className={`panel-choice-rhombus-wrapper ${selected ? "selected" : ""}`} id={id}>
      {selected && <div className="panel-choice-rhombus-border"></div>}
      <div
        className={`panel-choice-rhombus ${selected ? "panel-choice-rhombus-selected" : ""}`}
        data-hover-text={label}
        style={{ backgroundImage: `url(${image})` }}
      >
        <div
          className="info-icon6"
          ref={infoIconRef}
        >
          i
        </div>
      </div>
    </div>
  );
};

export default PanelChoiceRhombus;
