export const validateEmail = (email) => {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
};


export const validatePostalCode = (postalCode) => {
  const regex = /^[0-9]{5}$/;
  return regex.test(postalCode);
};

export const validatePhone = (phone) => {
  const regex = /^(?:\+49)?\s?(?:0)?\s?([0-9]{2,4})\s?-?([0-9]{3,4})\s?-?([0-9]{4})$/;
  return regex.test(phone);
};
