export const translateColorCode = (colorCode) => {
  const colorMap = {
    // Pole and Facade Colors
    '4.1.a': 'Anthrazit', '4.3.a': 'Anthrazit',
    '4.1.b': 'Braun', '4.3.b': 'Braun',
    '4.1.c': 'Hellbraun', '4.3.c': 'Hellbraun',
    '4.1.d': 'Silber', '4.3.d': 'Silber',
    '4.1.e': 'Schwarz', '4.3.e': 'Schwarz',
    '4.1.f': 'Weiß', '4.3.f': 'Weiß',
    // Roof Colors
    '4.2.a': 'Anthrazit',
    '4.2.b': 'Braun',
    '4.2.c': 'Silber',
    '4.2.d': 'Schwarz',
  };

  return colorMap[colorCode] || 'Unknown Color';
};
