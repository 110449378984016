import React, { useState } from "react";
import "./ListComponent.css";

const DefaultListItem = ({ id, text, selected, onSelect }) => {
  const handleClick = () => {
    onSelect(id);
  };

  return (
    <button
      className={`default-list-item ${selected ? "selected" : ""}`}
      id={id}
      onClick={handleClick}
    >
      <span className="list-text">{text}</span>
    </button>
  );
};

const ListComponent = ({
  number,
  title,
  items,
  toggleOpen,
  isOpen,
  renderListItem,
}) => {
  const [selectedItemId, setSelectedItemId] = useState(null);

  const onSelect = (id) => {
    setSelectedItemId(id);
  };

  return (
    <div>
      <div className={`list-header ${isOpen ? "open" : ""}`} onClick={toggleOpen}>
        <div
          className="list-number"
          style={{ fontFamily: "'Montserrat', sans-serif", fontWeight: 700 }}
        >
          {number}
        </div>
        <div className="list-title">{title}</div>
        <div className="list-toggle-icon"></div>
      </div>
      <div className={`transition-list ${isOpen ? "open" : ""}`}>
        {items.map((item, index) =>
          renderListItem ? (
            React.cloneElement(renderListItem(item, index), { key: index })
          ) : (
            <DefaultListItem
              key={index}
              id={item.id}
              text={item.text}
              selected={selectedItemId === item.id}
              onSelect={onSelect}
            />
          )
        )}
      </div>
    </div>
  );
};

export default ListComponent;
