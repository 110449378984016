import React, { useEffect, useRef } from "react";
import "./ModelChoiceRhombus.css";
import { isIOS } from "../../utils/isIOS";

const ModelChoiceRhombus = ({ id, image, selected, toggleInfoWindow, hoverText, showInfo }) => {
  const infoIconRef = useRef(null);

  useEffect(() => {
    if (!showInfo) return;

    const handleTouchStart = (e) => {
      e.stopPropagation();
      toggleInfoWindow(id);
    };

    const handleClick = (e) => {
      e.stopPropagation();
      toggleInfoWindow(id);
    };

    const infoIconElement = infoIconRef.current;

    if (infoIconElement) {
      if (isIOS()) {
        infoIconElement.addEventListener("touchstart", handleTouchStart);
      } else {
        infoIconElement.addEventListener("click", handleClick);
      }
    }

    return () => {
      if (infoIconElement) {
        if (isIOS()) {
          infoIconElement.removeEventListener("touchstart", handleTouchStart);
        } else {
          infoIconElement.removeEventListener("click", handleClick);
        }
      }
    };
  }, [id, toggleInfoWindow, showInfo]);

  return (
    <div className={`model-choice-rhombus-wrapper ${selected ? "selected" : ""}`} id={id}>
      <div
        className={`model-choice-rhombus ${selected ? "model-choice-rhombus-selected" : ""}`}
        data-hover-text={hoverText}
      >
        <div
          className={`model-choice-image ${selected ? "model-choice-image-selected" : ""}`}
          style={{ backgroundImage: `url(${image})` }}
        ></div>
        {showInfo && (
          <div
            className="info-icon"
            ref={infoIconRef}
          >
            i
          </div>
        )}
      </div>
    </div>
  );
};

export default ModelChoiceRhombus;
