import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { selectPostalCode } from "../../store/reducers/formInfoSlice";
import { selectSelectedId as selectSelectedModelId } from "../../store/reducers/modelChoiceSlice";
import { selectDimensionsBySelectedId } from "../../store/reducers/dimensionsSlice";
import { selectShedOptionsState } from "../../store/reducers/shedOptionsSlice";
import { selectColorChoices } from "../../store/reducers/colorChoiceSlice";
import { selectSelectedId as isSelectedPanelColorId } from "../../store/reducers/panelColorChoiceSlice";
import { selectSelectedId as selectSelectedPanelId } from "../../store/reducers/panelChoiceSlice";
import { selectSelectedSquares } from "../../store/reducers/panelPlacementSlice";
import {
  countSelectedSquaresByModel,
  modelToPanelPrefix,
} from "../Summary/PDFGenerationUtils/choiceTranslationUtils/countingUtils";
import { calculateGaragePrice, calculateGatePrice } from "./garagePriceUtils";
import { calculateRoomPrice } from "./roomPriceUtils";
import { getPriceForDimensions } from "./dimensionPriceUtils";
import {
  getAntykondensatPrice,
  calculateAntykondensatCost,
} from "./antykondensatPriceUtils";
import { getPhotovoltaicsReinforcementPrice } from "./photovoltaicsPriceUtils";
import { getPanelPrice, calculatePanelsPrice } from "./panelsPriceUtils";
import { calculateMaskownicaPremiumPrice } from "./maskownicaPriceUtils";
import { calculateGutterPrice } from "./gutterPriceUtils";
import {
  calculateNumberOfPoles,
  calculatePolePrice,
} from "./polesPriceUtils";
import {
  setFinalPrice,
  setFinalBruttoPrice,
  setDimensionPrice,
  setPanelPrice,
  setPanelsPrice,
  setPhotovoltaicsReinforcementPrice,
  setAntykondensatCost,
  setRoomPrice,
  setGaragePrice,
  setGatePrice,
  setMaskownicaPrice,
  setMaskownicaPremiumPrice,
  setPolePrice,
  setGutterPrice,
} from "../../store/reducers/finalPriceSlice"; // Import all actions

const PriceCalculator = () => {
  const [dimensionsData, setDimensionsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [finalPrice, setFinalPriceState] = useState(0); // Add state for finalPrice
  const dispatch = useDispatch(); // Initialize dispatch

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://marciniakogrodzenia.pl/wp-content/uploads/2024/07/compiled_data2.json"
        );
        setDimensionsData(response.data);
        setLoading(false);
      } catch (err) {
        setError(`Error fetching data: ${err.message}`);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const postalCode = useSelector(selectPostalCode);
  const selectedModelId = useSelector(selectSelectedModelId);
  const dimensions = useSelector((state) =>
    selectDimensionsBySelectedId(state, selectedModelId)
  );
  const shedOptions = useSelector(selectShedOptionsState);
  const colorChoices = useSelector(selectColorChoices);
  const selectedPanelColorId = useSelector(isSelectedPanelColorId);
  const selectedPanelId = useSelector(selectSelectedPanelId);
  const selectedSquares = useSelector(selectSelectedSquares);
  const garageOptions = shedOptions.selectedGarageOption;
  const isWallTypeChecked = shedOptions.isWallTypeChecked;

  useEffect(() => {
    if (!loading && !error) {
      let calculatedPrice = 0;

      const numberOfPoles = calculateNumberOfPoles(
        selectedModelId,
        dimensions.length,
        selectedSquares
      );

      if (dimensions && dimensions.length && dimensions.width) {
        const dimensionPrice = getPriceForDimensions(
          dimensionsData,
          dimensions.length,
          dimensions.width
        );

        //console.log(`kalkulacja kosztu na podstawie wymiarów: ${dimensionPrice}`);
        calculatedPrice += dimensionPrice;
        dispatch(setDimensionPrice(dimensionPrice)); // Dispatch the dimensionPrice
      }

      const panelPrice = getPanelPrice(
        selectedPanelId,
        selectedPanelColorId,
        dimensionsData
      );

      //console.log(`koszt pojedyńczego panelu: ${panelPrice}`);
      
      dispatch(setPanelPrice(panelPrice)); // Dispatch the panelPrice

      let panelsPrice = calculatePanelsPrice(
        selectedModelId,
        dimensions,
        selectedSquares,
        shedOptions,
        garageOptions,
        panelPrice,
        modelToPanelPrefix,
        countSelectedSquaresByModel
      );
   
      //panelsPrice = panelsPrice * 1.25 * 0.9;
      //console.log(`koszt łączny paneli: ${panelsPrice}`);
      
      calculatedPrice += panelsPrice ;
      
      dispatch(setPanelsPrice(panelsPrice)); // Dispatch the panelsPrice

      const photovoltaicReinforcementPrice = getPhotovoltaicsReinforcementPrice(
        dimensionsData,
        dimensions
      );

      if (shedOptions.isPhotovoltaicsReinforcementChecked) {
        //console.log(`fotowoltaika koszt: ${photovoltaicReinforcementPrice}`);
        calculatedPrice += photovoltaicReinforcementPrice;
        dispatch(setPhotovoltaicsReinforcementPrice(photovoltaicReinforcementPrice)); // Dispatch the photovoltaicsReinforcementPrice
      }

      const antykondensatPrice = getAntykondensatPrice(dimensionsData);
      const antykondensatCost = calculateAntykondensatCost(
        dimensions,
        antykondensatPrice
      );
      //console.log(`koszt antykondensatu: ${antykondensatCost}`);
      calculatedPrice += antykondensatCost;
      dispatch(setAntykondensatCost(antykondensatCost)); // Dispatch the antykondensatCost

    
      // Calculate both room and garage prices
      let roomPrice = 0;
      let garagePrice = 0;
      let gatePrice = 0;

      if (shedOptions.isRoomChecked) {
        roomPrice = calculateRoomPrice(
          dimensionsData,
          dimensions,
          shedOptions.selectedRoomOption,
          isWallTypeChecked
        );
      }

      if (shedOptions.isGarageChecked) {
        garagePrice = calculateGaragePrice(
          dimensions,
          garageOptions,
          dimensionsData,
          isWallTypeChecked
        );
        gatePrice = calculateGatePrice(
          dimensions,
          garageOptions,
          dimensionsData
        );
      }

      // Ensure only one price is sent, based on the final selection
      if (shedOptions.isRoomChecked && !shedOptions.isGarageChecked) {
        calculatedPrice += roomPrice;
        dispatch(setRoomPrice(roomPrice));
        dispatch(setGaragePrice(0)); // Ensure garage price is reset
        dispatch(setGatePrice(0)); // Ensure gate price is reset
      } else if (shedOptions.isGarageChecked && !shedOptions.isRoomChecked) {
        calculatedPrice += garagePrice + gatePrice;
        dispatch(setGaragePrice(garagePrice));
        dispatch(setGatePrice(gatePrice));
        dispatch(setRoomPrice(0)); // Ensure room price is reset
      } else {
        // Fallback to ensure no price is sent if neither is selected (edge case)
        dispatch(setRoomPrice(0));
        dispatch(setGaragePrice(0));
        dispatch(setGatePrice(0));
      }
      const facadeColor = colorChoices.facadeColor;
      const facadeColorIds = ["4.3.b", "4.3.c", "4.3.d", "4.3.e", "4.3.f"];
      if (facadeColorIds.includes(facadeColor)) {
        const maskownicaStandard = dimensionsData.find(
          (d) => d["Rozmiar (szer. x dł.)"] === "Maskownica Standard"
        );
        const maskownicaPrice = maskownicaStandard
          ? maskownicaStandard["Cena Netto (zł) zaciagana do konfiguratora"]
          : 0;
        calculatedPrice += maskownicaPrice;
        dispatch(setMaskownicaPrice(maskownicaPrice)); // Dispatch the maskownicaPrice
        //console.log(`koszt maskownicy: ${maskownicaPrice}`);
      }

      const maskownicaPremiumPrice = calculateMaskownicaPremiumPrice(
        dimensionsData,
        dimensions,
        shedOptions
      );
      //console.log(`koszt maskownicy premium: ${maskownicaPremiumPrice}`);
      calculatedPrice += maskownicaPremiumPrice;
      dispatch(setMaskownicaPremiumPrice(maskownicaPremiumPrice)); // Dispatch the maskownicaPremiumPrice

      const singlePolePrice =
        dimensionsData.find(
          (d) =>
            d["Rozmiar (szer. x dł.)"] === "Słup pojedynczy \ndla wysokości 2,7m"
        )?.["Cena Netto (zł) zaciagana do konfiguratora"] || 0;

      const polePrice = calculatePolePrice(
        dimensions,
        numberOfPoles,
        selectedModelId,
        selectedSquares,
        singlePolePrice,
        dimensionsData,
        shedOptions
      );
      //console.log(`ilosc słupów: ${numberOfPoles}`);
      //console.log(`koszt słupów: ${polePrice}`);
      calculatedPrice += polePrice;
      dispatch(setPolePrice(polePrice)); // Dispatch the polePrice

      const gutterPrice = calculateGutterPrice(
        dimensionsData,
        shedOptions.isGutterTypeChecked
      );
      calculatedPrice += gutterPrice;
      dispatch(setGutterPrice(gutterPrice)); // Dispatch the gutterPrice
      //console.log(gutterPrice)
      //console.log(calculatedPrice)
      const finalBruttoPrice = calculatedPrice * 1.23;
      dispatch(setFinalBruttoPrice(finalBruttoPrice)); // Dispatch the finalBruttoPrice
      //console.log(finalBruttoPrice)
      // Update state and dispatch the final price to the store
      setFinalPriceState(calculatedPrice);
      dispatch(setFinalPrice(calculatedPrice));
    }
  }, [loading, error, dimensionsData, dispatch, selectedModelId, dimensions, selectedSquares, shedOptions, colorChoices, selectedPanelColorId, selectedPanelId, garageOptions, isWallTypeChecked]);

  if (loading) return <div >Loading...</div>;
  if (error) return <div>{error}</div>;

  const numberOfPoles = calculateNumberOfPoles(
    selectedModelId,
    dimensions.length,
    selectedSquares
  );

  return (
    <div >
      {/*
           <div>
        Szacowana cena netto: {finalPrice}
        </div>
      <div>Szacowana cena brutto: {(finalPrice * 1.23).toFixed(2)}</div>
      <div>Ilość słupów: {numberOfPoles}</div>
      
      */}
 
    </div>
  );
};

export default PriceCalculator;
