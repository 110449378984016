import React, { useState, useEffect, useCallback } from "react";
import Slider from "./Slider";
import "./SliderContainer.css";
import useInfoWindow from "../../../hooks/useInfoWindow";
import { infoData } from "../../../utils/infoData";

const SliderContainer = ({
  id,
  min,
  max,
  realMin,
  realMax,
  dimension,
  onDimensionChange,
  selectedId,
  showInfoIcon,
  customDimensionText,
  pgDimensionInfo,  
}) => {
  const [value, setValue] = useState(min);
  const [inputValue, setInputValue] = useState(min.toString());
  const [displayValue, setDisplayValue] = useState(min.toString());
  const [rangeWarning, setRangeWarning] = useState("");
  const [customText, setCustomText] = useState("");
  const [pgInfoText, setPgInfoText] = useState("");
  const { toggleInfoWindow, renderInfoWindow } = useInfoWindow(id);

  const dispatchSliderEvent = useCallback(
    (newValue) => {
      const sliderElem = document.getElementById(id);
      if (sliderElem) {
        sliderElem.value = newValue;
        sliderElem.dispatchEvent(new Event("input"));
      }
    },
    [id]
  );

  const mapValueToRealRange = useCallback(
    (sliderValue) => {
      return ((realMax - realMin) * sliderValue + realMin).toFixed(3);
    },
    [realMin, realMax]
  );

  const mapRealRangeToValue = useCallback(
    (realValue) => {
      return ((realValue - realMin) / (realMax - realMin)).toFixed(3);
    },
    [realMin, realMax]
  );

  const checkRange = useCallback(
    (val) => {
      if (val < realMin) {
        setRangeWarning(`Minimale ${dimension} fur Carport beträgt ${realMin}`);
      } else if (val > realMax) {
        setRangeWarning(`Maximale ${dimension} fur Carport beträgt ${realMax}`);
      } else {
        setRangeWarning("");
      }
    },
    [realMin, realMax, dimension]
  );

  useEffect(() => {
    setValue(min);
    setInputValue(min.toString());
    setDisplayValue(realMin.toString());
    setRangeWarning("");
    onDimensionChange(min.toString());
  }, [selectedId]);

  useEffect(() => {
    dispatchSliderEvent(min);
  }, [selectedId, dispatchSliderEvent, min]);

  useEffect(() => {
    setInputValue(mapValueToRealRange(value));
    setDisplayValue(mapValueToRealRange(value).split(".")[0] + " cm");
    checkRange(parseFloat(mapValueToRealRange(value)));
  }, [value, mapValueToRealRange, checkRange]);

  const handleSliderChange = (e) => {
    const newValue = e.target.valueAsNumber;
    if (!isNaN(newValue) && newValue >= min && newValue <= max) {
      setValue(newValue);
      onDimensionChange(mapValueToRealRange(newValue));
      dispatchSliderEvent(newValue);
    }
  };

  const handleNumericInputChange = (e) => {
    const newRealValue = parseFloat(e.target.value);
    setInputValue(e.target.value);
    checkRange(newRealValue);

    if (
      !isNaN(newRealValue) &&
      newRealValue >= realMin &&
      newRealValue <= realMax
    ) {
      setValue(mapRealRangeToValue(newRealValue));
      onDimensionChange(e.target.value);
      dispatchSliderEvent(mapRealRangeToValue(newRealValue));
    }
  };

  const handleNumericInputBlur = () => {
    const newRealValue = parseFloat(inputValue);
    if (!isNaN(newRealValue) && newRealValue >= realMin && newRealValue <= realMax) {
      // If a valid number is entered, continue as normal
      setValue(mapRealRangeToValue(newRealValue));
      onDimensionChange(mapValueToRealRange(newRealValue));
      setDisplayValue(mapValueToRealRange(newRealValue).split(".")[0] + " cm");
    } else {
      // If no valid number is entered or field is left empty, reset to the original displayed value
      setInputValue(mapValueToRealRange(value));
      setDisplayValue(mapValueToRealRange(value).split(".")[0] + " cm");
    }
  };

  // This function is used to handle the input display behavior
  const handleFakeInputBlur = () => {
    const newRealValue = parseFloat(displayValue);
    if (!isNaN(newRealValue) && newRealValue >= realMin && newRealValue <= realMax) {
      // If a valid number is entered, continue as normal
      setValue(mapRealRangeToValue(newRealValue));
      onDimensionChange(mapValueToRealRange(newRealValue));
      setDisplayValue(newRealValue.toString() + " cm");
    } else {
      // If no valid number is entered or field is left empty, reset to the original displayed value
      setDisplayValue(mapValueToRealRange(value).split(".")[0] + " cm");
    }
  };

  const handleNumericInputFocus = () => {
    // Temporarily clear the display when input field is focused
    setDisplayValue("");
  };

  const handleFakeInputChange = (e) => {
    const numericValue = parseFloat(e.target.value);
    const newDisplayValue = isNaN(numericValue) ? "" : numericValue.toString();

    setDisplayValue(newDisplayValue + " cm");
    checkRange(numericValue);

    if (
      !isNaN(numericValue) &&
      numericValue >= realMin &&
      numericValue <= realMax
    ) {
      setValue(mapRealRangeToValue(numericValue));
      onDimensionChange(numericValue.toString());
      dispatchSliderEvent(mapRealRangeToValue(numericValue));
    }
  };

  useEffect(() => {
    const realValue = parseFloat(mapValueToRealRange(value)); // Ensure it's a float
    setDisplayValue(`${Math.round(realValue)} cm`);
    checkRange(realValue);

    // Only compute and set custom text if customDimensionText is provided
    if (customDimensionText) {
      setCustomText(customDimensionText(Math.round(realValue))); // Pass rounded value
    } else {
      setCustomText(""); // Clear custom text if not provided
    }
  }, [value, mapValueToRealRange, checkRange, customDimensionText]);

  useEffect(() => {
    if (pgDimensionInfo) {
      const realValue = parseFloat(mapValueToRealRange(value));
      setPgInfoText(pgDimensionInfo(Math.round(realValue)));
    } else {
      setPgInfoText("");
    }
  }, [value, mapValueToRealRange, pgDimensionInfo]);


  return (
    <div className="slider-container">
      <div className="dimension-display">
        <input
          className="dimension-input"
          type="text"
          value={dimension}
          readOnly
        />
        {rangeWarning && <p className="range-warning">{rangeWarning}</p>}
        {showInfoIcon && (
          <div
            className="info-icon2"
            onClick={(e) => {
              e.stopPropagation();
              toggleInfoWindow(id);
            }}
            title="More information"
          >
            i
          </div>
        )}
      </div>
      <div>
        
        <div>
          <input
            type="text"
            value={inputValue}
            onChange={handleNumericInputChange}
            onBlur={handleNumericInputBlur}
            style={{ display: "none" }}
          />
          <div className="input-wrapper">
            <div className="inner-wrapper">
              <div className="input-container">
              <input
              type="text"
              value={displayValue.split(" ")[0]}
              className="fake-input"
              onFocus={handleNumericInputFocus}
              onChange={handleFakeInputChange}
              onBlur={handleFakeInputBlur}
            />
            <div className="unit">cm</div>
          

              </div>
            
            </div>
            <Slider
              id={id}
              min={min}
              max={max}
              value={value}
              onChange={handleSliderChange}
            />
          
          </div>
        </div>
        {customText && <div className="custom-dimension-text">{customText}</div>}
        {pgInfoText && <div className="custom-dimension-text">{pgInfoText}</div>} 
      </div>
      
      {renderInfoWindow()}
    </div>
  );
};

export default SliderContainer;
