import React from 'react';
import useInfoWindow from "../../hooks/useInfoWindow";
import './HighlightedCheckboxComponent.css';

const HighlightedCheckboxComponent = ({
  id,
  checked,
  onChange,
  labelBaseText,
  labels,
  showInfoIcon = false
}) => {
  const { toggleInfoWindow, renderInfoWindow } = useInfoWindow();

  return (
    <div className='highlighted-checkbox-wrapper'>
      <input
        type="checkbox"
        id={id}
        className="highlighted-custom-checkbox"
        checked={checked}
        onChange={onChange}
      />
      <label htmlFor={id} className="base-label">
        {labelBaseText} - 
        <span className={checked ? "label-unselected" : "label-selected"}>
          {labels.unchecked}
        </span>
        /
        <span className={checked ? "label-selected" : "label-unselected"}>
          {labels.checked}
        </span>
      </label>
      {showInfoIcon && (
        <div
          className="info-icon11"
          onClick={(e) => {
            e.stopPropagation();
            toggleInfoWindow(id); // Pass the id here
          }}
          title="More information"
        >
          i
        </div>
      )}
      {renderInfoWindow()}
    </div>
  );
};

export default HighlightedCheckboxComponent;
