import React, { useState, useEffect } from 'react';
import './RedirectButton.css';
import HomeIcon1 from './hd1.png';
import HomeIcon2 from './hd2.png';
import RedirectHomePopup from '../RedirectHomePopup/RedirectHomePopup';

const RedirectButton = () => {
  const [icon, setIcon] = useState(HomeIcon1);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  useEffect(() => {
    const adjustButtonPosition = () => {
      const hateElement = document.getElementById('appui');

      if (hateElement) {
        const rect = hateElement.getBoundingClientRect();
        const hateLeftEdge = rect.left;

        if (hateLeftEdge !== 0) {  // Only adjust if the position is valid
          //console.log('Hate Element Left Edge:', hateLeftEdge); // Log the position
          const button = document.querySelector('.redirect-button');
          if (button) {
            //console.log('Setting Button Position:', hateLeftEdge);
            button.style.position = 'absolute'; 
            button.style.left = `${hateLeftEdge}px`;
            button.style.bottom = '2%'; 
            button.style.right = 'auto'; 
          }
        } else {
          // If the element isn't ready, retry after a short delay
          setTimeout(adjustButtonPosition, 100);
        }
      }
    };

    adjustButtonPosition(); // Initial adjustment on load
    window.addEventListener('resize', adjustButtonPosition); 

    return () => {
      window.removeEventListener('resize', adjustButtonPosition);
    };
  }, []);

  const handleRedirect = () => {
    setIsPopupOpen(true);
  };

  const handleMouseEnter = () => {
    setIcon(HomeIcon2);
  };

  const handleMouseLeave = () => {
    setIcon(HomeIcon1);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  return (
    <>
      <button
        className="redirect-button"
        onClick={handleRedirect}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <img src={icon} alt="Home" className="redirect-icon" />
      </button>
      {isPopupOpen && <RedirectHomePopup onClose={handleClosePopup} />}
    </>
  );
};

export default RedirectButton;
