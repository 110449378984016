import React from "react";
import "./PanelColorChoiceRhombus.css";

const PanelColorChoiceRhombus = ({ id, image, selected, isVisible, hoverText }) => (
  <div
    className={`panel-color-choice-rhombus-wrapper ${selected ? "selected" : ""} ${isVisible ? "" : "hidden"
      }`}
    id={id}

  >
    <div
      className={`panel-color-choice-rhombus ${selected ? "panel-color-choice-rhombus-selected" : ""
        }`}
      data-hover-text={hoverText}
    >
      <img src={image} alt="Color placeholder" />
    </div>
  </div>
);

export default PanelColorChoiceRhombus;
