import React from 'react';
import './FeedbackInput.css'; 

const FeedbackInput = ({ type = 'text', placeholder, value, onChange, isRequired = false, isValid = true, icon }) => {
  const className = `feedback-input feedback ${!isValid && isRequired ? 'invalid' : ''}`;

  return (
    <div className="feedback-container">
      <textarea
        className={className}
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        aria-required={isRequired}
        aria-invalid={!isValid && isRequired}
        maxLength={250}
        style={{ overflowY: 'auto' }}
      />
    </div>
  );
};

export default FeedbackInput;
