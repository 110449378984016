import React, { useRef } from 'react';
import useInfoWindow from "../../hooks/useInfoWindow";
import "./PlusCheckboxComponent.css";

const PlusCheckboxComponent = ({
  id,
  checked,
  onChange,
  label,
  labelClass,
  customCheckboxClass,
  checkboxBorderClass,
  disabled,
  showInfoIcon = false,
}) => {
  const { toggleInfoWindow, renderInfoWindow } = useInfoWindow(id);
  const checkboxRef = useRef(null);

  const handleWrapperClick = (e) => {
    e.stopPropagation(); // Prevents the click from being caught by parent elements
    if (!disabled && e.target.tagName !== 'INPUT') {
      checkboxRef.current.click();
    }
  };

  return (
    <div className={`plus-checkbox-wrapper ${disabled ? 'disabled' : ''}`} onClick={handleWrapperClick}>
      <div className={`checkbox-toggle-icon ${checked ? "open" : ""}`} />
      <div className='plus-checkbox-only-wrapper'>
        <input
          type="checkbox"
          id={id}
          ref={checkboxRef}
          className={`custom-checkbox ${customCheckboxClass}`}
          disabled={disabled}
          checked={checked}
          onChange={onChange}
        />
        {checked && (
          <div className={`checkbox-border ${checkboxBorderClass}`}></div>
        )}
      </div>
      <label htmlFor={id} className={labelClass} style={{ marginTop: '6px' }} onClick={(e) => e.stopPropagation()}>
        {label}
      </label>
      {showInfoIcon && (
        <div
          className="info-icon3"
          onClick={(e) => {
            e.stopPropagation();
            toggleInfoWindow(id);
          }}
          title="More information"
        >
          i
        </div>
      )}
      {renderInfoWindow()}
    </div>
  );
};

export default PlusCheckboxComponent;
