const squaresData1 = [
  { id: "6.1.y", top: "27.5%", left: "9.8%" },
  { id: "6.1.z", top: "46.8%", left: "9.8%" },
  { id: "6.1.a", top: "27.5%", left: "9.8%" },
  { id: "6.1.b", top: "46.8%", left: "9.8%" },
  { id: "6.1.c", top: "66.2%", left: "9.8%" },
  { id: "6.1.d", top: "8%", left: "31.5%" },
  { id: "6.1.e", top: "8%", left: "52.4%" },
  { id: "6.1.f", top: "27.5%", left: "74.2%" },
  { id: "6.1.g", top: "46.8%", left: "74.2%" },
  { id: "6.1.h", top: "66.2%", left: "74.2%" },
];

const squaresData1Extended = [
  { id: "6.1.b", top: "42.6%", left: "9.8%" },
  { id: "6.1.y", top: "60.6%", left: "9.8%" },
  { id: "6.1.c", top: "78.6%", left: "9.8%" },
  { id: "6.1.d", top: "8%", left: "31.5%" },
  { id: "6.1.e", top: "8%", left: "52.4%" },
  { id: "6.1.g", top: "42.6%", left: "74.2%" },
  { id: "6.1.z", top: "60.6%", left: "74.2%" },
  { id: "6.1.h", top: "78.6%", left: "74.2%" },
  { id: "6.1.a", top: "24.7%", left: "9.8%" },
  { id: "6.1.f", top: "24.7%", left: "74.2%" },

];

const squaresData2 = [
  { id: "6.1.y", top: "27.5%", left: "9.8%" },
  { id: "6.1.z", top: "46.8%", left: "9.8%" },
  { id: "6.1.a", top: "27.5%", left: "9.8%" },
  { id: "6.1.b", top: "46.8%", left: "9.8%" },
  { id: "6.1.c", top: "66.2%", left: "9.8%" },
  { id: "6.1.d", top: "8%", left: "31.5%" },
  { id: "6.1.e", top: "8%", left: "52.4%" },
  { id: "6.1.f", top: "27.5%", left: "74.2%" },
  { id: "6.1.g", top: "46.8%", left: "74.2%" },
  { id: "6.1.h", top: "66.2%", left: "74.2%" },
];

const squaresData2Extended = [
  { id: "6.1.b", top: "42.6%", left: "9.8%" },
  { id: "6.1.y", top: "60.6%", left: "9.8%" },
  { id: "6.1.c", top: "78.6%", left: "9.8%" },
  { id: "6.1.d", top: "8%", left: "31.5%" },
  { id: "6.1.e", top: "8%", left: "52.4%" },
  { id: "6.1.g", top: "42.6%", left: "74.2%" },
  { id: "6.1.z", top: "60.6%", left: "74.2%" },
  { id: "6.1.h", top: "78.6%", left: "74.2%" },
  { id: "6.1.a", top: "24.7%", left: "9.8%" },
  { id: "6.1.f", top: "24.7%", left: "74.2%" },

];

const squaresData3 = [
  { id: "6.1.y", top: "27.5%", left: "9.8%" },
  { id: "6.1.z", top: "46.8%", left: "9.8%" },
  { id: "6.1.a", top: "27.5%", left: "9.8%" },
  { id: "6.1.b", top: "46.8%", left: "9.8%" },
  { id: "6.1.c", top: "66.2%", left: "9.8%" },
  { id: "6.1.d", top: "8%", left: "31.5%" },
  { id: "6.1.e", top: "8%", left: "52.4%" },
  { id: "6.1.f", top: "27.5%", left: "74.2%" },
  { id: "6.1.g", top: "46.8%", left: "74.2%" },
  { id: "6.1.h", top: "66.2%", left: "74.2%" },
];

const squaresData3Extended = [
  { id: "6.1.b", top: "42.6%", left: "9.8%" },
  { id: "6.1.y", top: "60.6%", left: "9.8%" },
  { id: "6.1.c", top: "78.6%", left: "9.8%" },
  { id: "6.1.d", top: "8%", left: "31.5%" },
  { id: "6.1.e", top: "8%", left: "52.4%" },
  { id: "6.1.g", top: "42.6%", left: "74.2%" },
  { id: "6.1.z", top: "60.6%", left: "74.2%" },
  { id: "6.1.h", top: "78.6%", left: "74.2%" },
  { id: "6.1.a", top: "24.7%", left: "9.8%" },
  { id: "6.1.f", top: "24.7%", left: "74.2%" }
];

const squaresData4 = [
  { id: "6.1.y", top: "27.5%", left: "9.8%" },
  { id: "6.1.z", top: "46.8%", left: "9.8%" },
  { id: "6.1.a", top: "27.5%", left: "9.8%" },
  { id: "6.1.b", top: "46.8%", left: "9.8%" },
  { id: "6.1.c", top: "66.2%", left: "9.8%" },
  { id: "6.1.d", top: "8%", left: "31.5%" },
  { id: "6.1.e", top: "8%", left: "52.4%" },
  { id: "6.1.f", top: "27.5%", left: "74.2%" },
  { id: "6.1.g", top: "46.8%", left: "74.2%" },
  { id: "6.1.h", top: "66.2%", left: "74.2%" },
];

const squaresData4Extended = [
  { id: "6.1.b", top: "42.6%", left: "9.8%" },
  { id: "6.1.y", top: "60.6%", left: "9.8%" },
  { id: "6.1.c", top: "78.6%", left: "9.8%" },
  { id: "6.1.d", top: "8%", left: "31.5%" },
  { id: "6.1.e", top: "8%", left: "52.4%" },
  { id: "6.1.g", top: "42.6%", left: "74.2%" },
  { id: "6.1.z", top: "60.6%", left: "74.2%" },
  { id: "6.1.h", top: "78.6%", left: "74.2%" },
  { id: "6.1.a", top: "24.7%", left: "9.8%" },
  { id: "6.1.f", top: "24.7%", left: "74.2%" }
];

const squaresData5 = [
  { id: "6.1.y", top: "27.5%", left: "9.8%" },
  { id: "6.1.z", top: "46.8%", left: "9.8%" },
  { id: "6.1.a", top: "27.5%", left: "9.8%" },
  { id: "6.1.b", top: "46.8%", left: "9.8%" },
  { id: "6.1.c", top: "66.2%", left: "9.8%" },
  { id: "6.1.d", top: "8%", left: "31.5%" },
  { id: "6.1.e", top: "8%", left: "52.4%" },
  { id: "6.1.f", top: "27.5%", left: "74.2%" },
  { id: "6.1.g", top: "46.8%", left: "74.2%" },
  { id: "6.1.h", top: "66.2%", left: "74.2%" },
];

const squaresData5Extended = [
  { id: "6.1.b", top: "42.6%", left: "9.8%" },
  { id: "6.1.y", top: "60.6%", left: "9.8%" },
  { id: "6.1.c", top: "78.6%", left: "9.8%" },
  { id: "6.1.d", top: "8%", left: "31.5%" },
  { id: "6.1.e", top: "8%", left: "52.4%" },
  { id: "6.1.g", top: "42.6%", left: "74.2%" },
  { id: "6.1.z", top: "60.6%", left: "74.2%" },
  { id: "6.1.h", top: "78.6%", left: "74.2%" },
  { id: "6.1.a", top: "24.7%", left: "9.8%" },
  { id: "6.1.f", top: "24.7%", left: "74.2%" }
];

const squaresData6 = [
  { id: "6.1.y", top: "27.5%", left: "9.8%" },
  { id: "6.1.z", top: "46.8%", left: "9.8%" },
  { id: "6.1.a", top: "27.5%", left: "9.8%" },
  { id: "6.1.b", top: "46.8%", left: "9.8%" },
  { id: "6.1.c", top: "66.2%", left: "9.8%" },
  { id: "6.1.d", top: "8%", left: "31.5%" },
  { id: "6.1.e", top: "8%", left: "52.4%" },
  { id: "6.1.f", top: "27.5%", left: "74.2%" },
  { id: "6.1.g", top: "46.8%", left: "74.2%" },
  { id: "6.1.h", top: "66.2%", left: "74.2%" },
];

const squaresData6Extended = [
  { id: "6.1.b", top: "42.6%", left: "9.8%" },
  { id: "6.1.y", top: "60.6%", left: "9.8%" },
  { id: "6.1.c", top: "78.6%", left: "9.8%" },
  { id: "6.1.d", top: "8%", left: "31.5%" },
  { id: "6.1.e", top: "8%", left: "52.4%" },
  { id: "6.1.g", top: "42.6%", left: "74.2%" },
  { id: "6.1.z", top: "60.6%", left: "74.2%" },
  { id: "6.1.h", top: "78.6%", left: "74.2%" },
  { id: "6.1.a", top: "24.7%", left: "9.8%" },
  { id: "6.1.f", top: "24.7%", left: "74.2%" }
];

export { squaresData1, squaresData1Extended,
   squaresData2, squaresData2Extended,
    squaresData3, squaresData3Extended, 
    squaresData4, squaresData4Extended, 
    squaresData5, squaresData5Extended, 
    squaresData6, squaresData6Extended };
