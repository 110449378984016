import { useSelector } from 'react-redux';
import { selectEmail } from '../../../../store/reducers/formInfoSlice';

const useGenerateOrderNumber = () => {
    const email = useSelector(selectEmail);

    const generateOrderNumber = () => {
        const currentDate = new Date();
        const day = currentDate.getDate().toString().padStart(2, '0');
        const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
        const year = currentDate.getFullYear().toString().slice(-2);

        const datePart = `${day}${month}${year}`;
        const emailPart = email && email.charAt(0).toUpperCase();
        const minutesPart = currentDate.getMinutes().toString().padStart(2, '0');
        return `K${datePart}${emailPart}${minutesPart}`;
    };

    return generateOrderNumber;
};

export default useGenerateOrderNumber;
