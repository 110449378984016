import { createSlice } from "@reduxjs/toolkit";

export const shedOptionsSlice = createSlice({
  name: "shedOptions",
  initialState: {
    isGarageChecked: false,
    isRoomChecked: false,
    selectedGarageOption: [],
    selectedRoomOption: null,
    isSnowZoneChecked: false,
    isPhotovoltaicsReinforcementChecked: false,
    shedPosition: [],
    isShedPositionChecked: false,
    isMontageChecked: false,  
    lastActionOrigin: null,
    isGutterTypeChecked: false,    
    isGrilleTypeChecked: false,   
    isWallTypeChecked: false,
  },
  reducers: {
    toggleGarage: (state, action) => {
      state.isGarageChecked = action.payload ?? !state.isGarageChecked;
      if (state.isGarageChecked) {
        state.isRoomChecked = false;
        state.selectedRoomOption = null;
      }
      state.selectedGarageOption = []; // Clear or set based on additional logic
    },
    
    toggleRoom: (state, action) => {
      state.isRoomChecked = action.payload ?? !state.isRoomChecked;
      if (state.isRoomChecked) {
        state.isGarageChecked = false;
        state.selectedGarageOption = [];
      }
      state.selectedRoomOption = null; // Clear or set based on additional logic
    },
    
    handleOptionChange: (state, action) => {
      const { type, option } = action.payload;
      if (type === "garage") {
        const index = state.selectedGarageOption.indexOf(option);
        if (index !== -1) {
          state.selectedGarageOption.splice(index, 1);
        } else {
          state.selectedGarageOption.push(option);
        }
      } else if (type === "room") {
        state.selectedRoomOption = state.selectedRoomOption === option ? null : option;
      }
      state.lastActionOrigin = origin;
    },
    resetOptions: (state) => {
      state.isGarageChecked = false;
      state.isRoomChecked = false;
      state.selectedGarageOption = [];
      state.selectedRoomOption = null;
      state.isSnowZoneChecked = false;
      state.shedPosition = [];
      state.isShedPositionChecked = false;
      state.isPhotovoltaicsReinforcementChecked = false;
      state.isMontageChecked = false;  
      state.isGutterTypeChecked = false;
      state.isGrilleTypeChecked = false;
      state.isWallTypeChecked = false;
    },
    resetAdditionalOptions: (state) => {
      state.isGutterTypeChecked = false;
      state.isGrilleTypeChecked = false;
      state.isWallTypeChecked = false;
    },
    resetWallOptions: (state) => {
      state.isWallTypeChecked = false;
    },
    toggleShedPositionOption: (state, action) => {
      const option = action.payload;
      if (state.shedPosition.includes(option)) {
        state.shedPosition = state.shedPosition.filter(
          (item) => item !== option
        );
      } else {
        state.shedPosition.push(option);
      }
    },
    toggleShedPosition: (state) => {
      state.isShedPositionChecked = !state.isShedPositionChecked;
      if (!state.isShedPositionChecked) {
        state.shedPosition = [];
      }
    },
    toggleGutterType: (state) => {
      state.isGutterTypeChecked = !state.isGutterTypeChecked;
    },
    toggleGrilleType: (state) => {
      state.isGrilleTypeChecked = !state.isGrilleTypeChecked;
    },
    toggleWallType: (state) => {
      state.isWallTypeChecked = !state.isWallTypeChecked;
    },
    toggleSnowZone: (state) => {
      state.isSnowZoneChecked = !state.isSnowZoneChecked;
    },
    togglePhotovoltaicsReinforcement: (state) => {
      state.isPhotovoltaicsReinforcementChecked = !state.isPhotovoltaicsReinforcementChecked;
    },
    toggleMontage: (state) => {  
      state.isMontageChecked = !state.isMontageChecked;
    },
  },
});

export const {
  toggleGarage,
  toggleRoom,
  handleOptionChange,
  resetOptions,
  resetAdditionalOptions,
  resetWallOptions,
  toggleSnowZone,
  togglePhotovoltaicsReinforcement,
  toggleShedPosition,
  toggleShedPositionOption,
  toggleMontage,  
  toggleGutterType,
  toggleGrilleType,
  toggleWallType,
} = shedOptionsSlice.actions;

export const selectShedOptionsState = (state) => state.shedOptions;
export const selectLastActionOriginShedOptions = (state) => state.shedOptions.lastActionOrigin;

export default shedOptionsSlice.reducer;
