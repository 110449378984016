import { createSlice } from "@reduxjs/toolkit";

export const panelPlacementSlice = createSlice({
  name: "panelPlacement",
  initialState: {
    selectedSquares: {},
  },
  reducers: {
    toggleSquare: (state, action) => {
      const squareId = action.payload;
      state.selectedSquares[squareId] = !state.selectedSquares[squareId];
    },
    clearSelection: (state) => {
      state.selectedSquares = {};
    },
    updateToInitialSelection: (state) => {
      state.selectedSquares = {
        "6.1.a": true,
        "6.1.b": true,
        "6.1.c": true,
        "6.1.d": true,
        "6.1.e": true,
        "6.1.f": true,
        "6.1.g": true,
        "6.1.h": true,
      
      };
    },
    updateToExtendedInitialSelection: (state) => {
      state.selectedSquares = {
        "6.1.a": true,
        "6.1.b": true,
        "6.1.c": true,
        "6.1.d": true,
        "6.1.e": true,
        "6.1.f": true,
        "6.1.g": true,
        "6.1.h": true,
        "6.1.y": true,
        "6.1.z": true,
      };
    },
  },
});

export const { toggleSquare, clearSelection, updateToInitialSelection, updateToExtendedInitialSelection } = panelPlacementSlice.actions;
export const selectSelectedSquares = (state) =>
  state.panelPlacement.selectedSquares;
export default panelPlacementSlice.reducer;
