const panelColorIdToName = {
  '7.a': 'Anthrazit',
  '7.b': 'Braun',
  '7.c': 'Hellbraun',
  '7.d': 'Silber',
  '7.e': 'Schwarz',
  '7.f': 'Weiß',
};

export const translatePanelColorId = (colorId) => {
  return panelColorIdToName[colorId] || 'Unknown Color';
};
