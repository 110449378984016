import React from "react";
import "./Slider.css";

const Slider = ({ id, min, max, value, onChange }) => {
  return (
    <input
      type="range"
      id={id}
      min={min}
      max={max}
      step={0.001}
      value={value}
      onChange={onChange}
      className="dimensionslider"
    />
  );
};

export default Slider;
