import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  dimensionsBySelectedId: {},
};

export const dimensionsSlice = createSlice({
  name: "dimensions",
  initialState,
  reducers: {
    setDimension: (state, action) => {
      const { selectedId, dimensionType, value } = action.payload;
      if (!state.dimensionsBySelectedId[selectedId]) {
        state.dimensionsBySelectedId[selectedId] = {};
      }
      state.dimensionsBySelectedId[selectedId][dimensionType] = value;
    },
    resetDimensions: (state, action) => {
      const { selectedId } = action.payload;
      if (state.dimensionsBySelectedId[selectedId]) {
        state.dimensionsBySelectedId[selectedId] = {};
      }
    },
  },
});

export default dimensionsSlice.reducer;
export const { setDimension, resetDimensions } = dimensionsSlice.actions;

export const selectDimensionsBySelectedId = (state, selectedId) =>
  state.dimensions.dimensionsBySelectedId[selectedId] || {};
