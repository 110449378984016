import React from 'react';
import './CloseSummaryButton.css';

const CloseSummaryButton = ({ onClick }) => (
  <div
    className="close-button flex justify-center items-center text-white"
    onClick={onClick}
  >
    X
  </div>
);

export default CloseSummaryButton;
