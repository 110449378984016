import { useState, useEffect } from 'react';

const useCaptcha = () => {
  const [captchaQuestion, setCaptchaQuestion] = useState('');
  const [userCaptchaInput, setUserCaptchaInput] = useState('');
  const [captchaSolution, setCaptchaSolution] = useState(null);
  const [isCaptchaSolved, setIsCaptchaSolved] = useState(false);

  useEffect(() => {
    resetCaptcha();
  }, []);

  const handleCaptchaInputChange = (e) => {
    const input = parseInt(e.target.value, 10);
    setUserCaptchaInput(e.target.value);
    if (input === captchaSolution) {
      setIsCaptchaSolved(true);
    } else {
      setIsCaptchaSolved(false);
    }
  };

  const resetCaptcha = () => {
    const num1 = Math.floor(Math.random() * 10) + 1;
    const num2 = Math.floor(Math.random() * 10) + 1;
    setCaptchaQuestion(`${num1} + ${num2}`);
    setCaptchaSolution(num1 + num2);
    setUserCaptchaInput('');
    setIsCaptchaSolved(false);
  };

  return { captchaQuestion, userCaptchaInput, handleCaptchaInputChange, isCaptchaSolved, resetCaptcha };
};

export default useCaptcha;
