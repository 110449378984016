import React from 'react';
import "./LogoIcon.css";

const LogoIcon = () => {
    return (
        <div className="logo-icon-div">
            <img
                src="mhfav.png"
                alt="Logo"
                className='logo-icon-id'
            />
        </div>
    );
};

export default LogoIcon;
