import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  poleColor: "4.1.a",
  roofColor: "4.2.a",
  facadeColor: "4.3.a",
};

export const colorChoiceSlice = createSlice({
  name: "colorChoice",
  initialState,
  reducers: {
    setSelectedColorId: (state, action) => {
      const { category, id } = action.payload;
      state[category] = id;
    },
    resetColorChoices: () => initialState,
  },
});

export default colorChoiceSlice.reducer;
export const { setSelectedColorId, resetColorChoices } =
  colorChoiceSlice.actions;
export const selectColorChoices = (state) => state.colorChoice;
