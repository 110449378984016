import { createSlice } from "@reduxjs/toolkit";

export const modelChoiceSlice = createSlice({
  name: "modelChoice",
  initialState: {
    selectedId: "1.a",
    lastActionOrigin: null,
  },
  reducers: {
    setSelectedId: (state, action) => {
      state.selectedId = action.payload;
    },
    setSelectedIdFromOptions: (state, action) => {
      if (state.selectedId !== action.payload.id) {
        state.selectedId = action.payload.id;
        state.lastActionOrigin = action.payload.origin;
      }
    },
  },
});

export const { setSelectedId, setSelectedIdFromOptions } = modelChoiceSlice.actions;

export const selectSelectedId = (state) => state.modelChoice.selectedId;
export const selectLastActionOriginModelChoice = (state) => state.modelChoice.lastActionOrigin;


export default modelChoiceSlice.reducer;
