import React from 'react';
import "./MobileInfoIcon.css";

const MobileInfoIcon = () => {
    return (
        <div className="mobileinfo-icon-div">
            <img
                src="touch.png"
                alt="Logo"
                className='mobileinfo-icon-id'
            />
        </div>
    );
};

export default MobileInfoIcon;
