import { createSlice } from "@reduxjs/toolkit";

export const panelChoiceSlice = createSlice({
  name: "panelChoice",
  initialState: {
    selectedId: "5.a",
  },
  reducers: {
    setSelectedId: (state, action) => {
      state.selectedId = action.payload;
    },
    resetPanelChoice: (state) => {
      state.selectedId = "5.a";
    },
  },
});

export const { setSelectedId, resetPanelChoice } = panelChoiceSlice.actions;
export const selectSelectedId = (state) => state.panelChoice.selectedId;
export default panelChoiceSlice.reducer;
