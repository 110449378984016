export const getPriceForDimensions = (dimensionsData, length, width) => {
  const availableSizes = dimensionsData.map((d) => ({
    length: parseInt(d["Rozmiar (szer. x dł.)"].split("x")[0], 10),
    width: parseInt(d["Rozmiar (szer. x dł.)"].split("x")[1], 10),
    price: d["Cena Netto (zł) zaciagana do konfiguratora"],
  }));

  const roundDimension = (dimension) => {
    const base = Math.floor(dimension / 100) * 100;
    return dimension % 100 > 30 ? base + 100 : base;
  };

  const roundedLength = roundDimension(length);
  const roundedWidth = roundDimension(width);

  const matchedDimension = availableSizes.find(
    (size) => size.length === roundedLength && size.width === roundedWidth
  );

  return matchedDimension ? matchedDimension.price : 0;
};
