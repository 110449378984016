// polesPriceUtils.js

export const calculateNumberOfPoles = (modelId, length, selectedSquares) => {
  let numberOfPoles;
  switch (modelId) {
    case "1.a":
    case "1.b":
      numberOfPoles = length >= 700 ? 11 : 9;
      break;
    case "1.c":
      numberOfPoles = length >= 700 ? 22 : 18;
      break;
    case "1.d":
      numberOfPoles = length >= 700 ? 15 : 12;
      break;
    case "1.e":
      numberOfPoles = length >= 700 ? 16 : 13;
      break;
    case "1.f":
      numberOfPoles = length >= 700 ? 14 : 12;
      break;
    default:
      numberOfPoles = 0;
  }

  if (
    modelId === "1.a" &&
    !selectedSquares["6.1.d"] &&
    !selectedSquares["6.1.e"]
  ) {
    numberOfPoles -= 1;
  } else if (
    modelId === "1.b" &&
    !selectedSquares["6.1.d"] &&
    !selectedSquares["6.1.e"]
  ) {
    numberOfPoles -= 1;
  } else if (modelId === "1.c") {
    if (!selectedSquares["6.1.d"]) numberOfPoles -= 1;
    if (!selectedSquares["6.1.e"]) numberOfPoles -= 1;
  } else if (
    modelId === "1.e" &&
    !selectedSquares["6.1.d"] &&
    !selectedSquares["6.1.e"]
  ) {
    numberOfPoles -= 1;
  }

  return numberOfPoles;
};

export const calculatePolePrice = (
  dimensions,
  numberOfPoles,
  selectedModelId,
  selectedSquares,
  singlePolePrice,
  dimensionsData,
  shedOptions
) => {
  const pole2mm =
    dimensionsData.find(
      (d) => d["Rozmiar (szer. x dł.)"] === "Słup 100x100x2mm \ndo 3m wysokości"
    )?.["Cena Netto (zł) zaciagana do konfiguratora"] || 0;
  const pole3mm =
    dimensionsData.find(
      (d) =>
        d["Rozmiar (szer. x dł.)"] === "Słup 100x100x3mm \npowyżej 3m wysokości"
    )?.["Cena Netto (zł) zaciagana do konfiguratora"] || 0;

  let polePrice = 0;

  if (dimensions.height >= 270 && dimensions.height < 300) {
    polePrice = ((dimensions.height - 270) / 100) * numberOfPoles * pole2mm;
  } else if (dimensions.height >= 300) {
    polePrice =
      2.35 * (pole3mm - pole2mm) * numberOfPoles +
      ((dimensions.height - 270) / 100) * numberOfPoles * pole3mm;
  }

  // Add logic for height below 270 cm
  if (dimensions.height < 270) {
    // Check for garage
    if (shedOptions.isGarageChecked) {
      polePrice += 4 * singlePolePrice;
      if (dimensions.length > 700) {
        polePrice += 5 * singlePolePrice;
      }
    }

    // Check for side room options
    const isSideRoomChecked =
      shedOptions.selectedRoomOption === "3.2.a" ||
      shedOptions.selectedRoomOption === "3.2.c";
    if (shedOptions.isRoomChecked && isSideRoomChecked) {
      polePrice += 4 * singlePolePrice;
      if (dimensions.length > 700) {
        polePrice += 5 * singlePolePrice;
      }
    }

    // Check for back room option
    if (
      shedOptions.isRoomChecked &&
      shedOptions.selectedRoomOption === "3.2.b"
    ) {
      polePrice += 4 * singlePolePrice;
    }
  }

  // Existing logic for other configurations
  const isDSelected = selectedSquares["6.1.d"];
  const isESelected = selectedSquares["6.1.e"];

  switch (selectedModelId) {
    case "1.a":
      if (isDSelected || isESelected) {
        polePrice += singlePolePrice;
      }
      break;
    case "1.b":
      if (isDSelected || isESelected) {
        polePrice += singlePolePrice;
      }
      break;
    case "1.c":
      if (isDSelected) {
        polePrice += singlePolePrice;
      }
      if (isESelected) {
        polePrice += singlePolePrice;
      }
      break;
    case "1.e":
      if (isDSelected || isESelected) {
        polePrice += singlePolePrice;
      }
      break;
    default:
      break;
  }

  return polePrice;
};
