import React from "react";
import "./LeaveButton.css";

const LeaveButton = ({ onClick }) => {
  return (
    <button
      className="LeaveButton"
      onClick={onClick}
    >
      Verlasse die Seite
    </button>
  );
};

export default LeaveButton;
