import { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import InfoWindow from '../components/miscellaneousComponents/InfoWindow/InfoWindow';
import { infoData } from '../utils/infoData';

const useInfoWindow = () => {
    const [infoWindowId, setInfoWindowId] = useState(null);

    const toggleInfoWindow = (id) => {
       // console.log(`toggleInfoWindow called with id: ${id}`); // Log the ID being toggled
        setInfoWindowId(prevId => {
            const newId = prevId === id ? null : id;
           // console.log(`Setting infoWindowId from ${prevId} to ${newId}`); // Log the state change
            return newId;
        });
    };

    useEffect(() => {
        const closeOnEscapeKey = (e) => {
            if (e.key === "Escape") {
             //   console.log('Escape key pressed, closing InfoWindow'); // Log Escape key usage
                setInfoWindowId(null);
            }
        };
        document.addEventListener("keydown", closeOnEscapeKey);

        return () => document.removeEventListener("keydown", closeOnEscapeKey);
    }, []);

    useEffect(() => {
        if (infoWindowId !== null) {
            //console.log(`InfoWindow with id ${infoWindowId} is open. Setting up click outside listener.`); // Log setup for click outside
            const closeOnOutsideClick = (event) => {
                if (!document.querySelector('.info-window').contains(event.target)) {
                   // console.log('Click outside InfoWindow detected, closing InfoWindow'); // Log outside click
                    setInfoWindowId(null);
                }
            };

            document.addEventListener('click', closeOnOutsideClick);
            return () => {
                //console.log('Removing click outside listener for InfoWindow'); // Log cleanup
                document.removeEventListener('click', closeOnOutsideClick);
            };
        }
    }, [infoWindowId]);

    const renderInfoWindow = (additionalProps = {}) => {
        return (
            infoWindowId &&
            ReactDOM.createPortal(
                <InfoWindow
                    id={infoWindowId}
                    image={infoData[infoWindowId]?.image || "default.png"}
                    text={infoData[infoWindowId]?.text || "Default Text"}
                    title={infoData[infoWindowId]?.title}
                    onClose={() => setInfoWindowId(null)}
                    {...additionalProps}
                />,
                document.body
            )
        );
    };

    return { toggleInfoWindow, renderInfoWindow };
};

export default useInfoWindow;
