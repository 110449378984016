import React from 'react';
import './CloseRedirectHomeButton.css';

const CloseRedirectHomeButton = ({ onClick }) => (
  <div
    className="RedirectHomePopup-close-btn flex justify-center items-center text-white"
    onClick={onClick}
  >
    X
  </div>
);

export default CloseRedirectHomeButton;
