const getModelTypeText = (selectedModelId) => {
  switch (selectedModelId) {
    case '1.a':
      return 'Einzelcarport';
    case '1.b':
      return 'Doppelcarport';
    case '1.c':
      return 'Reihen-Carport';
    case '1.d':
      return 'Doppelcarport';
    case '1.e':
      return 'Doppelcarport';
    case '1.f':
      return 'Doppelcarport';
  }
};

export default getModelTypeText;
