import React from 'react';
import "./RotateIcon.css";

const RotateIcon = () => {
    return (
        <div className="rotate-icon-div bg-opacity-50 backdrop-blur-md"
        style={{ backgroundColor: "rgba(255, 255, 255, 0.7)" }}>
            <img
                src="rotatephone.png"
                alt="Logo"
                className='rotate-icon-id'
            />
        </div>
    );
};

export default RotateIcon;
