const panelIdToTypeMap = {
  "5.a": "Ścianka Falista",
  "5.b": "Ścianka Horyzontal",
  "5.c": "Ścianka Modern+",
  "5.d": "Ścianka Żaluzja",
};

export const getPanelPrice = (panelId, panelColorId, dimensionsData) => {
  const panelType = panelIdToTypeMap[panelId];
  const panel = dimensionsData.find(
    (d) => d["Rozmiar (szer. x dł.)"] === panelType
  );
  let panelPrice = panel
    ? panel["Cena Netto (zł) zaciagana do konfiguratora"]
    : 0;

  if (panelColorId !== "7.a" && panelColorId !== "7.b") {
    if (panelId === "5.c") {
      panelPrice *= 1.2;
    } else {
      panelPrice *= 1.15;
    }
  }

  // Apply the additional multiplications
  panelPrice *= 1.25;
  panelPrice *= 0.9;

  return panelPrice;
};


export const calculatePanelsPrice = (
  selectedModelId,
  dimensions,
  selectedSquares,
  shedOptions,
  garageOptions,
  panelPrice,
  modelToPanelPrefix,
  countSelectedSquaresByModel
) => {
  let modelPrefix = modelToPanelPrefix[selectedModelId];
  let countOfSelectedSquares = countSelectedSquaresByModel(
    selectedSquares,
    modelPrefix
  );

  // Handle special case for 1.c model
  if (selectedModelId === "1.c") {
    if (selectedSquares["6.1.d"]) {
      countOfSelectedSquares += 2;
    }
    if (selectedSquares["6.1.e"]) {
      countOfSelectedSquares += 2;
    }
  }

  // Adjust count when squares are disabled
  if (selectedModelId === "1.c") {
    if (!selectedSquares["6.1.d"]) {
      countOfSelectedSquares = Math.max(0, countOfSelectedSquares - 2);
    }
    if (!selectedSquares["6.1.e"]) {
      countOfSelectedSquares = Math.max(0, countOfSelectedSquares - 2);
    }
  }

  // Existing logic for 1.a model
  //if (selectedModelId === "1.a" && selectedSquares["6.1.d"]) {
  //  countOfSelectedSquares += 1;
  //}
  //if (selectedModelId === "1.a") {
  //  countOfSelectedSquares = Math.max(0, countOfSelectedSquares - 1);
  //}

  // Garage and shed options logic
  if (dimensions.length > 700) {
    if (shedOptions.isGarageChecked) {
      if (garageOptions.includes("3.1.a") || garageOptions.includes("3.1.b")) {
        countOfSelectedSquares -= 5;
        if (
          garageOptions.includes("3.1.a") &&
          garageOptions.includes("3.1.b")
        ) {
          countOfSelectedSquares = 0;
        }
      }
    }
  } else {
    if (shedOptions.isGarageChecked) {
      if (garageOptions.includes("3.1.a") || garageOptions.includes("3.1.b")) {
        countOfSelectedSquares -= 4;
        if (
          garageOptions.includes("3.1.a") &&
          garageOptions.includes("3.1.b")
        ) {
          countOfSelectedSquares = 0;
        }
      }
    }
  }

  // Shed room options logic
  if (dimensions.length > 700) {
    if (
      shedOptions.selectedRoomOption === "3.2.a" ||
      shedOptions.selectedRoomOption === "3.2.c"
    ) {
      countOfSelectedSquares -= 4;
    } else if (shedOptions.selectedRoomOption === "3.2.b") {
      countOfSelectedSquares -= 2;
    }
  } else {
    if (
      shedOptions.selectedRoomOption === "3.2.a" ||
      shedOptions.selectedRoomOption === "3.2.c"
    ) {
      countOfSelectedSquares -= 3;
    } else if (shedOptions.selectedRoomOption === "3.2.b") {
      countOfSelectedSquares -= 2;
    }
  }

  // Calculate panels price
  let panelsPrice;
  //console.log(`ilosc paneli bocznych  ${countOfSelectedSquares}`)
  if (dimensions.height > 270) {
    panelsPrice =
      countOfSelectedSquares * panelPrice * ((dimensions.height - 41) / 229);
  } else {
    panelsPrice = countOfSelectedSquares * panelPrice;
  }

  return panelsPrice;
};
