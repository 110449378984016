import React from 'react';
import './BackgroundPlaceholderComponent.css';  

const BackgroundPlaceholderComponent = ({ selectedId, currentLength }) => {
  const rectangleClass = currentLength > 699 ? "rectangle-variant" : "rectangle";
  const squareClass = currentLength > 699 ? "square-variant" : "square";
  const rectangle1bClass = currentLength > 699 ? "rectangle-variant1b" : "rectangle1b";
  const square1bClass = currentLength > 699 ? "square-variant1b" : "square1b";

  return (
    <div className="background-placeholder">
      {selectedId === '1.a' && (
        <>
          <div className={rectangle1bClass}></div>
          <div className={square1bClass}></div>
        </>
      )}
      {selectedId === '1.b' && (
        <>
          <div className={rectangle1bClass}></div>
          <div className={square1bClass}></div>
        </>
      )}
      {selectedId === '1.c' && (
        <>
          <div className={rectangle1bClass}></div>
          <div className={square1bClass}></div>
        </>
      )}
       {selectedId === '1.d' && (
        <>
          <div className={rectangle1bClass}></div>
          <div className={square1bClass}></div>
        </>
      )}
      {selectedId === '1.e' && (
        <>
          <div className={rectangle1bClass}></div>
          <div className={square1bClass}></div>
        </>
      )}
       {selectedId === '1.f' && (
        <>
          <div className={rectangle1bClass}></div>
          <div className={square1bClass}></div>
        </>
      )}
    </div>
  );
}

export default BackgroundPlaceholderComponent;
