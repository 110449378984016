import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  finalPrice: 0,
  dimensionPrice: 0,
  panelPrice: 0,
  panelsPrice: 0,
  photovoltaicsReinforcementPrice: 0,
  antykondensatCost: 0,
  roomPrice: 0,
  garagePrice: 0,
  gatePrice: 0,
  maskownicaPrice: 0,
  maskownicaPremiumPrice: 0,
  polePrice: 0,
  gutterPrice: 0,
  finalBruttoPrice: 0,
};

const finalPriceSlice = createSlice({
  name: "finalPrice",
  initialState,
  reducers: {
    setFinalPrice: (state, action) => {
      state.finalPrice = action.payload;
    },
    setFinalBruttoPrice: (state, action) => {
      state.finalBruttoPrice = action.payload;
    },
    setDimensionPrice: (state, action) => {
      state.dimensionPrice = action.payload;
    },
    setPanelPrice: (state, action) => {
      state.panelPrice = action.payload;
    },
    setPanelsPrice: (state, action) => {
      state.panelsPrice = action.payload;
    },
    setPhotovoltaicsReinforcementPrice: (state, action) => {
      state.photovoltaicsReinforcementPrice = action.payload;
    },
    setAntykondensatCost: (state, action) => {
      state.antykondensatCost = action.payload;
    },
    setRoomPrice: (state, action) => {
      state.roomPrice = action.payload;
    },
    setGaragePrice: (state, action) => {
      state.garagePrice = action.payload;
    },
    setGatePrice: (state, action) => {
      state.gatePrice = action.payload;
    },
    setMaskownicaPrice: (state, action) => {
      state.maskownicaPrice = action.payload;
    },
    setMaskownicaPremiumPrice: (state, action) => {
      state.maskownicaPremiumPrice = action.payload;
    },
    setPolePrice: (state, action) => {
      state.polePrice = action.payload;
    },
    setGutterPrice: (state, action) => {
      state.gutterPrice = action.payload;
    },
  },
});

export const {
  setFinalPrice,
  setFinalBruttoPrice,
  setDimensionPrice,
  setPanelPrice,
  setPanelsPrice,
  setPhotovoltaicsReinforcementPrice,
  setAntykondensatCost,
  setRoomPrice,
  setGaragePrice,
  setGatePrice,
  setMaskownicaPrice,
  setMaskownicaPremiumPrice,
  setPolePrice,
  setGutterPrice,
} = finalPriceSlice.actions;

export const selectFinalPrice = (state) => state.finalPrice.finalPrice;
export const selectFinalBruttoPrice = (state) => state.finalPrice.finalBruttoPrice;
export const selectDimensionPrice = (state) => state.finalPrice.dimensionPrice;
export const selectPanelPrice = (state) => state.finalPrice.panelPrice;
export const selectPanelsPrice = (state) => state.finalPrice.panelsPrice;
export const selectPhotovoltaicsReinforcementPrice = (state) => state.finalPrice.photovoltaicsReinforcementPrice;
export const selectAntykondensatCost = (state) => state.finalPrice.antykondensatCost;
export const selectRoomPrice = (state) => state.finalPrice.roomPrice;
export const selectGaragePrice = (state) => state.finalPrice.garagePrice;
export const selectGatePrice = (state) => state.finalPrice.gatePrice;
export const selectMaskownicaPrice = (state) => state.finalPrice.maskownicaPrice;
export const selectMaskownicaPremiumPrice = (state) => state.finalPrice.maskownicaPremiumPrice;
export const selectPolePrice = (state) => state.finalPrice.polePrice;
export const selectGutterPrice = (state) => state.finalPrice.gutterPrice;


export default finalPriceSlice.reducer;
