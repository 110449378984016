export function showLoadingTextPortrait() {
  const loadingOverlay = document.createElement("div");
  loadingOverlay.id = "loadingOverlay";
  loadingOverlay.style.position = "absolute";
  loadingOverlay.style.top = "0";
  loadingOverlay.style.left = "0";
  loadingOverlay.style.width = "100%";
  loadingOverlay.style.height = "100%";
  loadingOverlay.style.backgroundColor = "rgba(255, 255, 255, 1)";
  loadingOverlay.style.zIndex = "1000";
  loadingOverlay.style.display = "flex";
  loadingOverlay.style.flexDirection = "column";
  loadingOverlay.style.justifyContent = "space-between";
  loadingOverlay.style.alignItems = "center";
  document.body.appendChild(loadingOverlay);

  // Text2Container at the top
  const topFlexRow = document.createElement("div");
  topFlexRow.style.display = "flex";
  topFlexRow.style.justifyContent = "center"; // Center horizontally
  topFlexRow.style.width = "100%";
  topFlexRow.style.padding = "20px"; 
  topFlexRow.style.position = "absolute";
  topFlexRow.style.top = "0";

  const text2Container = document.createElement("div");
  text2Container.style.display = "flex";
  text2Container.style.alignItems = "center";

  const text2 = document.createElement("div");
  text2.style.fontFamily = "Monsterat, sans-serif";
  text2.style.fontSize = "16px";
  text2.style.color = "#363636";
  text2.textContent = "Created by";
  text2Container.appendChild(text2);

  // Create an anchor element and wrap it around the logoSvg
  const logoLink = document.createElement("a");
  logoLink.href = "https://creative.stellarcompany.eu/";
  logoLink.target = "_blank";

  const logoSvg = document.createElement("img");
  logoSvg.src = "stlr-logo2.png";
  logoSvg.alt = "STLR Logo";
  logoSvg.style.width = "150px";
  logoSvg.style.height = "25px";
  logoSvg.style.marginLeft = "5px";

  // Append the logoSvg inside the anchor element
  logoLink.appendChild(logoSvg);
  text2Container.appendChild(logoLink);

  topFlexRow.appendChild(text2Container);

  loadingOverlay.appendChild(topFlexRow);

  // Logo in the center
  const logoImage = document.createElement("img");
  logoImage.src = "mh.png";
  logoImage.alt = "Loading Logo";
  logoImage.style.width = "300px";
  logoImage.style.height = "100px";
  logoImage.style.margin = "auto";
  loadingOverlay.appendChild(logoImage);

  // Loading bar with text below the logo
  const loadingBottomContainer = document.createElement("div");
  loadingBottomContainer.style.display = "flex";
  loadingBottomContainer.style.flexDirection = "column";
  loadingBottomContainer.style.alignItems = "center";
  loadingBottomContainer.style.width = "100%";
  loadingBottomContainer.style.marginBottom = "50px"; // Adjusted to move higher
  loadingOverlay.appendChild(loadingBottomContainer);

  const loadingBarContainer = document.createElement("div");
  loadingBarContainer.style.width = "60%";
  loadingBarContainer.style.maxWidth = "600px";
  loadingBarContainer.style.height = "15px";
  loadingBarContainer.style.backgroundColor = "#aeaeae";
  loadingBarContainer.style.borderRadius = "15px";
  loadingBarContainer.style.overflow = "hidden";
  loadingBottomContainer.appendChild(loadingBarContainer);

  const loadingBar = document.createElement("div");
  loadingBar.id = "loadingBar";
  loadingBar.style.width = "0%";
  loadingBar.style.height = "100%";
  loadingBar.style.backgroundColor = "#2e78cc";
  loadingBar.style.borderRadius = "15px 0 0 15px";
  loadingBarContainer.appendChild(loadingBar);

  const loadingText = document.createElement("div");
  loadingText.style.marginTop = "10px";
  loadingText.style.fontFamily = "Monsterat, sans-serif";
  loadingText.style.fontSize = "16px";
  loadingText.style.color = "#363636";
  loadingText.style.textAlign = "center"; // Ensuring text is centered
  loadingText.textContent =
      "Konfigurator wird geladen, bitte haben Sie einen Moment Geduld...";
  loadingBottomContainer.appendChild(loadingText);

  // Text1 at the bottom
  const bottomFlexRow = document.createElement("div");
  bottomFlexRow.style.display = "flex";
  bottomFlexRow.style.justifyContent = "center";
  bottomFlexRow.style.width = "100%";
  bottomFlexRow.style.padding = "0 20px";
  bottomFlexRow.style.position = "absolute";
  bottomFlexRow.style.bottom = "20px";

  const text1 = document.createElement("div");
  text1.style.fontFamily = "Monsterat, sans-serif";
  text1.style.fontSize = "16px";
  text1.style.color = "#363636";
  text1.textContent = "© 2024 MeinHaus24.eu GmbH";
  bottomFlexRow.appendChild(text1);

  loadingOverlay.appendChild(bottomFlexRow);
}
