import React from 'react';
import useInfoWindow from "../../hooks/useInfoWindow";
import "./CheckboxComponent.css";

const CheckboxComponent = ({
  id,
  checked,
  onChange,
  label,
  labelClass,
  customCheckboxClass,
  checkboxBorderClass,
  disabled,
  showInfoIcon = false,
}) => {
  const { toggleInfoWindow, renderInfoWindow } = useInfoWindow(id);

  return (
    <div className='checkbox-only-wrapper'>
      <div className="checkbox-only-wrapper2">
        <input
          type="checkbox"
          id={id}
          className={`custom-checkbox ${customCheckboxClass}`}
          disabled={disabled}
          checked={checked}
          onChange={onChange}
        />
        {checked && (
          <div className={`checkbox-border ${checkboxBorderClass}`}></div>
        )}
         <label htmlFor={id} className={labelClass}>
        {label}
      </label>
      </div>
     
      {showInfoIcon && (
        <div
          className="info-icon3"
          onClick={(e) => {
            e.stopPropagation();
            toggleInfoWindow(id);
          }}
          title="More information"
        >
          i
        </div>
      )}
      {renderInfoWindow()}
    </div>
  );
};

export default CheckboxComponent;
