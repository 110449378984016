import { createSlice } from "@reduxjs/toolkit";

export const panelColorChoiceSlice = createSlice({
  name: "panelColorChoice",
  initialState: {
    selectedId: "7.a",
  },
  reducers: {
    selectColor: (state, action) => {
      state.selectedId = action.payload;
    },
    resetPanelColorChoice: (state) => {
      state.selectedId = "7.a";
    },
  },
});

export const { selectColor, resetPanelColorChoice } =
  panelColorChoiceSlice.actions;

export const selectSelectedId = (state) => state.panelColorChoice.selectedId;

export default panelColorChoiceSlice.reducer;
