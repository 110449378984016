export const infoData = {
  "1.a": {
    text: "Sorgen Sie für einen hervorragenden Schutz für Ihr Fahrzeug mit einem Einzelstellplatz-Carport, der auf Langlebigkeit und Ästhetik ausgelegt ist. Die Konstruktion aus feuerverzinktem und pulverbeschichtetem Stahl garantiert eine langanhaltende Nutzung ohne regelmäßige Wartung. Dieser elegante Carport bietet umfassenden Schutz, indem er Ihr Auto wirksam vor schädlichen UV-Strahlen, Regen und starken Windböen schützt und es das ganze Jahr über in einwandfreiem Zustand hält.",
    image: "pojedyncza.jpg",
    title: "Einzelcarport",
  },
  "1.b": {
    text: "Ein Doppelstellplatz-Carport ist ein Synonym für Schutz und Raum. Dank der soliden Konstruktion aus hochwertigen Materialien bietet er einen sicheren Zufluchtsort für zwei Fahrzeuge vor widrigen Wetterbedingungen. Die elegante Verarbeitung und das moderne Design machen den Carport nicht nur zu einem praktischen, sondern auch zu einem ästhetischen Zusatz für jedes Grundstück. Der zusätzliche Raum kann auch als Abstellplatz für Fahrräder, Gartenwerkzeuge oder saisonale Ausrüstung genutzt werden.",
    image: "podwojna.jpg",
    title: "Doppelcarport",
  },
  "1.c": {
    text: "Verwandeln Sie Ihre Umgebung in einen Raum voller Eleganz und Funktionalität mit einem modernen Mehrfachstellplatz-Carport. Unsere Konstruktionen aus präzise ausgewählten Materialien wie feuerverzinktem Stahl stehen für Langlebigkeit und minimalen Wartungsaufwand. Dank dieser Lösungen bleibt Ihre Investition jahrelang rost- und schadensfrei, ohne regelmäßige Reparaturen zu benötigen. Dies ist die ideale Lösung für jeden, der Qualität und Ästhetik schätzt – von großen Familien über Entwickler bis hin zu Wohnungsverwaltungen. Indem Sie in unsere Carports investieren, investieren Sie in Ihre Zukunft, erhöhen den Wert und das Prestige Ihrer Immobilie und gewinnen die Sicherheit, dass Ihr Parkplatz über viele Jahre hinweg sicher, stilvoll und praktisch bleibt.",
    image: "zbrama.jpg",
    title: "Reihen-Carport",
  },
  "1.d": {
    text: "Sorgen Sie für den höchsten Schutz für Ihr Fahrzeug und Ihr Eigentum, indem Sie einen Carport mit Garage wählen. Unsere Konstruktionen garantieren Langlebigkeit und Widerstandsfähigkeit gegen verschiedene Wetterbedingungen. Die Garage bietet zusätzlichen Schutz, nicht nur vor Regen und Schnee, sondern auch vor möglichen Vandalismusakten. Das minimalistische Design und die moderne Ausführung sorgen dafür, dass unser Carport mit Garage perfekt zu jeder Immobilie passt und deren Wert und Funktionalität erhöht.",
    image: "wiatazgaraz.webp",
    title: "Carport mit Garage",
  },
  "1.e": {
    text: "Verwandeln Sie Ihr Grundstück, indem Sie einen Carport mit seitlichem Abstellraum wählen, der Eleganz und Funktionalität in einem vereint. Unsere Konstruktion, gefertigt aus hochwertigen Materialien, garantiert Langlebigkeit und Wetterbeständigkeit über viele Jahre hinweg. Der seitliche Abstellraum ist die perfekte Lösung für diejenigen, die zusätzlichen Stauraum für Werkzeuge, Gartengeräte oder Fahrräder benötigen, ohne auf die Ästhetik der Umgebung zu verzichten.",
    image: "wiatazboku.webp",
    title: "Carport mit seitlichem Abstellraum",
  },
  "1.f": {
    text: "Wählen Sie einen Carport mit rückwärtigem Abstellraum, um einen praktischen und ästhetischen Bereich auf Ihrem Grundstück zu schaffen. Unsere Konstruktionen, gefertigt aus den besten Materialien, bieten nicht nur Schutz für Ihr Fahrzeug, sondern auch zusätzlichen Stauraum für Werkzeuge, Gartengeräte oder andere notwendige Gegenstände. Der rückwärtig platzierte Abstellraum stört die Harmonie der Umgebung nicht, und das moderne Design sorgt dafür, dass das Gesamtbild stilvoll wirkt. ",
    image: "wiataztylu.webp",
    title: "Carport mit hinterem Abstellraum",
  },
  slider4: {
    text: "Die Verstrebungen sind das Fundament der Stabilität unserer Carports, da sie die Festigkeit der Konstruktion und den Schutz vor extremen Wetterbedingungen gewährleisten. Diese hochwertigen Stahlelemente sind entscheidend für die Lastverteilung und machen den Carport wind- und schneebeständig, während sie ihre elegante Ästhetik bewahren. Dank der Verstrebungen garantieren unsere Carports Zuverlässigkeit und Langlebigkeit und bieten einen sicheren Unterschlupf für Ihre Fahrzeuge.",
    image: "zbrama.jpg",
    title: "Dachüberstand",
  },
  "3.4.a": {
    text: "Unsere Carports sind so konzipiert, dass sie maximalen Schutz bei verschiedenen Wetterbedingungen bieten, einschließlich Gebieten mit unterschiedlicher Schneelast. Wir bieten Modelle, die an die Schneelastzonen 1, 2 und 3 angepasst sind, was eine ideale Anpassung der Konstruktion an die örtlichen Gegebenheiten ermöglicht. Dank der Verwendung hochwertiger Materialien und durchdachter Konstruktionslösungen garantieren unsere Carports Langlebigkeit und Stabilität selbst bei intensiven Schneefällen. Unabhängig davon, ob Sie Schutz in einem milden Klima oder in einer Region mit harten Winterbedingungen benötigen, bieten unsere Carports zuverlässigen Schutz für Ihre Fahrzeuge und Ausrüstung.",
    image: "zbrama.jpg",
    title: "Schneelastzone",
  },
  "3.8.a": {
    text: "Jeder Carport im Set enthält eine STANDARD-Verkleidung. Bestellen Sie zusätzlich die profilierte PREMIUM-Verkleidung – eine moderne und elegante Lösung, die das Erscheinungsbild Ihres Carports mit Sicherheit aufwerten wird! Die organische Beschichtung der Verkleidungen besteht aus thermoplastischer, zweischichtiger PVDF-Farbe mit einer Dicke von 35 μm und wurde bei hoher Temperatur polymerisiert.",
    image: "mask13.webp",
    title: "Abdeckung Standard/Premium",
  },
  "5.a": {
    text: "Die Paneele aus Wellstahl bieten einen vollständigen Schutz vor widrigen Wetterbedingungen. Das Fehlen von Durchlässen garantiert mehr Privatsphäre, und die darin aufbewahrten Gegenstände sind nicht sichtbar.",
    image: "scianka-falista.jpg",
    title: "Wellblech",
  },
  "5.b": {
    text: "Das Paneel wird auf der Basis eines gebogenen Stahlprofils hergestellt. Breite Lamellen (10 cm) sorgen für geringe Durchlässigkeit und ermöglichen Sichtkontakt mit der Umgebung.",
    image: "scianka-horyzontal.jpg",
    title: "Horizontal",
  },
  "5.c": {
    text: "Das Paneel wird auf der Basis eines gebogenen Stahlprofils hergestellt. Es zeichnet sich durch besonders breite Lamellen (29 cm) aus.",
    image: "scianka-modern.jpg",
    title: "Modern+",
  },
  "5.d": {
    text: "Modernes Jalousiesystem aus gebogenem Stahlprofil. Aufgrund der nahezu vollständigen Abdeckung und des Fehlens direkter Durchblicke bietet es Schutz für den Inhalt des Raumes.",
    image: "scianka-zaluzja.jpg",
    title: "Jalousie",
  },
};
