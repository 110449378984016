export const countSelectedSquaresByModel = (selectedSquares, modelPrefix) => {
    return Object.keys(selectedSquares)
        .filter(key => key.startsWith(modelPrefix) && selectedSquares[key])
        .length;
};
export const modelToPanelPrefix = {
    "1.a": "6.1",
    "1.b": "6.1",
    "1.c": "6.1",
    "1.d": "6.1",
    "1.e": "6.1",
    "1.f": "6.1",
};
