export const getPhotovoltaicsReinforcementPrice = (dimensionsData, dimensions) => {
  const reinforcementData = dimensionsData.find(
    (d) => d["Rozmiar (szer. x dł.)"] === "Wzmocnienie pod fotowoltaikę"
  );

  if (!reinforcementData) return 0;

  const photovoltaicsPriceFromJson = reinforcementData["Cena Netto (zł) zaciagana do konfiguratora"];
  const width = dimensions.width;

  if (width > 700) {
    return ((photovoltaicsPriceFromJson * (width / 100) + 100) * 2);
  } else {
    return (photovoltaicsPriceFromJson * (width / 100) + 100);
  }
};
