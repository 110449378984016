import React from "react";
import "./ColorChoiceRhombus.css";

const ColorChoiceRhombus = ({ id, image, selected, name }) => (
  <div
    className={`color-choice-rhombus-wrapper ${selected ? "selected" : ""}`}
    id={id}
  >
    <div
      className={`color-choice-rhombus  ${selected ? "color-choice-rhombus-selected" : ""
        }`}
      data-hover-text={name}
    >
      <img src={image} alt={name} />
    </div>
  </div>
);

export default ColorChoiceRhombus;